<script setup lang="ts">
    import { computed } from 'vue'
    import { useI18n } from 'vue-i18n'
    import { RouteLocationRaw } from 'vue-router'
    import MaterialIcon from '@/components/ui/icons/MaterialIcon.vue'
    import SpaRouterLink from '@/components/ui/navigations/SpaRouterLink.vue'
    import { ClubRouteName, InvestRouteName, RouteName } from '@/vars/RouteName'
    import { SpaName } from '@/vars/SpaAttr'

    export type Breadcrumb = {
        label: string
        to?: RouteLocationRaw
        loading?: boolean
    }[]

    defineProps<{
        breadcrumb: Breadcrumb
    }>()

    const homeRouteName = computed(() => {
        switch (import.meta.env.VITE_SPA_RUNNING) {
            case SpaName.INVEST:
                return InvestRouteName.HOME
            case SpaName.LECLUB:
                return ClubRouteName.HOME
            default:
                return RouteName.HOME
        }
    })

    const { t } = useI18n()
</script>

<template>
    <nav class="hidden md:block">
        <ol role="list" class="flex items-center space-x-4">
            <li>
                <div>
                    <SpaRouterLink :to="{ name: homeRouteName }" class="text-gray-400 hover:text-gray-500">
                        <MaterialIcon name="home" class="!block text-xl" />
                        <span class="sr-only">{{ t('home') }}</span>
                    </SpaRouterLink>
                </div>
            </li>
            <template v-for="route in breadcrumb" :key="route.label">
                <li>
                    <div class="flex items-center">
                        <MaterialIcon class="text-2xl text-gray-400" name="chevron_right" />
                        <Component
                            :is="route.to ? 'RouterLink' : 'span'"
                            v-placeholder="{
                                loading: route.loading,
                                options: {
                                    width: 100
                                }
                            }"
                            class="breadcrumb__text"
                            :class="{ 'hover:text-gray-700': route.to }"
                            :to="route.to"
                        >
                            {{ route.label }}
                        </Component>
                    </div>
                </li>
            </template>
        </ol>
    </nav>
</template>

<style lang="scss" scoped>
    .breadcrumb {
        &__text {
            @apply ml-4 text-sm font-medium text-gray-500;
        }
    }
</style>

<i18n lang="json">
{
    "fr": {
        "home": "Accueil"
    },
    "en": {
        "home": "Home"
    }
}
</i18n>
