import Http from './ApiClient'
import { ResponseV3, Meta } from '@/types/API.type'
import { ProjectSingle } from '@/types/Project.type'
import { EventCardType } from '@/types/EventCard.type'
import {
    ClubSingle,
    ClubMember,
    ClubMemberMeta,
    ClubBoard,
    ClubNews,
    ClubProject,
    ClubProjectMeta
} from '@/types/response/clubs-api/GetClubs'

class ClubsApi {
    static async getClub(slug: string): Promise<ResponseV3<ClubSingle>> {
        const response = await Http.get(`/v3/clubs/${slug}`)
        return response.data
    }

    static async getClubEvents(
        slug: string,
        params: {
            page: number
            type: 'upcoming' | 'past'
        }
    ): Promise<ResponseV3<EventCardType[], Meta>> {
        const response = await Http.get(`/v3/clubs/${slug}/events`, {
            params
        })

        return response.data
    }

    static async getClubMemberList(slug: string, page = 1): Promise<ResponseV3<ClubMember[], ClubMemberMeta>> {
        const response = await Http.get(`/v3/clubs/${slug}/members`, {
            params: {
                page
            }
        })

        return response.data
    }

    static async getClubMembers(slug: string, params = {}): Promise<ResponseV3<ClubMember[], ClubMemberMeta>> {
        const response = await Http.post(`/v3/clubs/${slug}/members`, params)

        return response.data
    }

    static async getClubBoard(slug: string): Promise<ResponseV3<ClubBoard[], undefined>> {
        const response = await Http.get(`/v3/clubs/${slug}/boards`)

        return response.data
    }

    static async getClubNews(slug: string, params = {}): Promise<ResponseV3<ClubNews[], Meta>> {
        const response = await Http.get(`/v3/clubs/${slug}/news`, {
            params
        })

        return response.data
    }
    static async getClubStructuredProjects(): Promise<ResponseV3<ClubProject[], ClubProjectMeta>> {
        const response = await Http.get(`/v3/projects/structured`)

        return response.data
    }

    static async getClubMarketplaceProjects(): Promise<ResponseV3<ClubProject[], Meta>> {
        const response = await Http.get(`/v3/projects/marketplace`)

        return response.data
    }

    static async getInvestmentProjects(): Promise<ResponseV3<ClubProject[]>> {
        const response = await Http.get(`/v3/public-homepage/dealflow`)

        return response.data
    }

    static async joinClub(slug: string): Promise<void> {
        await Http.post(`/v3/clubs/${slug}/join`)
    }

    static async askToJoinClub(slug: string, comment: string): Promise<void> {
        await Http.post(`/v3/clubs/${slug}/ask-to-join`, { comment })
    }
    static async getProject(slug: string): Promise<ResponseV3<ProjectSingle, undefined>> {
        const response = await Http.get(`/v3/projects/${slug}`)

        return response.data
    }
    static async addProjectNotInterest(slug: string): Promise<void> {
        await Http.post(`/v3/projects/${slug}/not-interested`)
    }
    static async addProjectSponsor(slug: string): Promise<void> {
        await Http.post(`/v3/projects/${slug}/sponsor`)
    }
    static async addProjectHelp(slug: string, comment: string): Promise<void> {
        await Http.post(`/v3/projects/${slug}/help`, { comment })
    }
    static async addProjectInterest(slug: string, comment?: string): Promise<void> {
        await Http.post(`/v3/projects/${slug}/interested`, { comment })
    }
}

export default ClubsApi
