import { computed, ComputedRef } from 'vue'
import useAccountProfileNavigation from '@/libs/navigations/common/useAccountProfileNavigation'
import useAdminProfileNavigation from '@/libs/navigations/common/useAdminProfileNavigation'
import { ProfileNavigationItem } from '@/libs/navigations/useProfileNavigation'

export default function useDefaultProfileNavigation(): ComputedRef<ProfileNavigationItem[]> {
    const adminProfileNavigation = useAdminProfileNavigation()
    const accountProfileNavigation = useAccountProfileNavigation()

    const defaultNav = computed<ProfileNavigationItem[]>(() => {
        return [...adminProfileNavigation.value, ...accountProfileNavigation.value]
    })

    return defaultNav
}
