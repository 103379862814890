import { RouteRecordRaw } from 'vue-router'
import PublicAcademyLayout from '@/components/layouts/academy/PublicAcademyLayout.vue'
import EmptyLayout from '@/components/layouts/EmptyLayout.vue'
import { AcademyRouteName } from '@/vars/RouteName'

const routes: RouteRecordRaw[] = [
    {
        path: '/',
        name: AcademyRouteName.HOME,
        component: () => import('@/views/academy/home/AcademyHome.vue'),
        meta: {
            layout: PublicAcademyLayout
        }
    },
    {
        path: '/course',
        name: AcademyRouteName.INTRODUCE_COURSE,
        component: () => import('@/views/academy/course/CourseIntroduction.vue'),
        meta: {
            layout: PublicAcademyLayout
        }
    },
    {
        path: '/pre-register',
        name: AcademyRouteName.PRE_REGISTER,
        component: () => import('@/views/academy/pre-register/AcademyPreRegister.vue'),
        meta: {
            layout: EmptyLayout,
            hideWhatsNew: true
        }
    },
    {
        name: AcademyRouteName.WEWEB_CGU,
        path: '/page/cgu-academy',
        component: () => import('@/views/common/IframeWeweb.vue'),
        meta: {
            layout: PublicAcademyLayout,
            hideWhatsNew: true
        }
    },
    {
        name: AcademyRouteName.WEWEB_CGV,
        path: '/page/cgv-academy',
        component: () => import('@/views/common/IframeWeweb.vue'),
        meta: {
            layout: PublicAcademyLayout,
            hideWhatsNew: true
        }
    },
    {
        name: AcademyRouteName.WEWEB_LEGAL_MENTIONS,
        path: '/page/mentions-legales-academy',
        component: () => import('@/views/common/IframeWeweb.vue'),
        meta: {
            layout: PublicAcademyLayout,
            hideWhatsNew: true
        }
    }
]

export default routes
