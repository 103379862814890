<script setup lang="ts">
    import { computed, provide, ref } from 'vue'
    import { useI18n } from 'vue-i18n'
    import { OnClickOutside } from '@vueuse/components'
    import { useManualRefHistory } from '@vueuse/core'
    import HeaderMobileNavigation from '@/components/layouts/leclub/header/navigations/HeaderMobileNavigation.vue'
    import HeaderNavigationProvider from '@/components/layouts/leclub/header/navigations/HeaderNavigationProvider.vue'
    import HeaderProfileNavigation from '@/components/layouts/leclub/header/navigations/profile/HeaderProfileNavigation.vue'
    import UniversalSearchResults from '@/components/partials/universal-search/UniversalSearchResults.vue'
    import ContainerTemplate from '@/components/templates/ContainerTemplate.vue'
    import BaseButton from '@/components/ui/basics/BaseButton.vue'
    import PlateformLogoNavigation from '@/components/ui/navigations/PlateformLogoNavigation.vue'
    import useProfileNavigation from '@/libs/navigations/useProfileNavigation'
    import { useAuthStore } from '@/libs/stores/auth.store'
    import { HeaderMenu } from '@/types/Header.type'
    import { RoleName } from '@/vars/AuthAttr'
    import { ButtonSize, ButtonTheme } from '@/vars/ButtonAttr'
    import { RouteName } from '@/vars/RouteName'
    import { UNIVERSAL_SEARCH_KEY, UniversalSearchInjectType } from '@/vars/UniversalSearchAttr'
    import MainNavigationLayout from './MainNavigationLayout.vue'

    const currentUrl = window.location.href

    const universalSearchIsExpended = ref<boolean>(false)
    const universalSearchText = ref<string>('')
    const { history, commit } = useManualRefHistory(universalSearchText, {
        capacity: 8
    })
    const store = useAuthStore()
    const profileNavigation = useProfileNavigation()
    const { t } = useI18n()

    const isLogin = computed(() => {
        return store.isLoggedIn
    })

    const isClubMember = computed(() => {
        return store.isLoggedIn && store.hasRole(RoleName.LECLUB)
    })

    function updateIsExpended(newValue: boolean) {
        universalSearchIsExpended.value = newValue
    }

    function updateSearchText(text: string) {
        universalSearchText.value = text
    }

    function saveHistory() {
        if (universalSearchText.value !== '') {
            commit()
        }
    }

    provide<UniversalSearchInjectType>(UNIVERSAL_SEARCH_KEY, {
        isExpended: universalSearchIsExpended,
        searchText: universalSearchText,
        updateIsExpended,
        updateSearchText,
        history,
        saveHistory
    })

    const closeSearch = () => {
        if (universalSearchIsExpended.value) {
            updateIsExpended(false)
        }
    }
</script>

<template>
    <header>
        <OnClickOutside @trigger="closeSearch">
            <div class="header">
                <ContainerTemplate class="header__container">
                    <HeaderNavigationProvider>
                        <template #default="{ navigationItems }">
                            <HeaderMobileNavigation
                                :navigation-items="navigationItems as HeaderMenu[]"
                                class="header__navigation header__navigation--mobile"
                            />
                            <div class="flex-1">
                                <div class="flex justify-between lg:mb-2 lg:pt-4">
                                    <PlateformLogoNavigation />

                                    <MainNavigationLayout
                                        v-if="isClubMember"
                                        :navigation-items="navigationItems as HeaderMenu[]"
                                        class="header__navigation header__navigation--desktop"
                                    />
                                    <HeaderProfileNavigation
                                        v-if="isLogin"
                                        :profile="profileNavigation"
                                        show-app-switcher
                                    />
                                    <div v-else class="header__action">
                                        <BaseButton
                                            class="header__action--login"
                                            :theme="ButtonTheme.PRIMARY"
                                            :size="ButtonSize.SMALL"
                                            rounded
                                            is-pill
                                            :to="{ name: RouteName.LOGIN, query: { redirectUrl: currentUrl } }"
                                        >
                                            {{ t('login') }}
                                        </BaseButton>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </HeaderNavigationProvider>
                </ContainerTemplate>
            </div>
            <UniversalSearchResults v-if="universalSearchIsExpended" />
        </OnClickOutside>
    </header>
</template>

<style scoped lang="scss">
    @import '@/libs/sass/vars';
    .header {
        position: relative;
        display: flex;
        justify-content: center;
        height: var(--header-height--mobile);
        --theme-text-color--header: var(--theme-background-color);
        color: var(--theme-text-color--header);
        box-shadow: 0 0 0.1875em var(--theme-shadow-color);
        z-index: 100;

        @media screen and (min-width: $breakpoints-lg) {
            height: var(--header-height);
            justify-content: space-between;
            align-items: flex-start;
        }

        &__container {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &__logo {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: var(--scale-2);
            @media only screen and (min-width: $breakpoints-lg) {
                margin-right: var(--scale-4);
            }
        }
        &__logo-bar {
            width: var(--scale-px);
            height: 100%;
            background-color: var(--theme-color-nuage);
            margin-inline: var(--scale-2);
        }

        &__navigation {
            &--mobile {
                display: block;
                margin-right: var(--scale-4);
                @media only screen and (min-width: $breakpoints-lg) {
                    display: none;
                }
            }
            &--desktop {
                display: none;
                @media only screen and (min-width: $breakpoints-lg) {
                    display: block;
                    flex-grow: 1;
                }
            }
        }

        &__action {
            align-self: center;
            display: flex;
            gap: 1em;
            @media only screen and (max-width: $breakpoints-sm) {
                font-size: 0.75em;
            }
            &--login {
                border-color: var(--theme-primary-color);
                @media only screen and (max-width: $breakpoints-sm) {
                    display: none;
                }
            }
        }
    }
</style>

<i18n lang="json">
{
    "fr": {
        "login": " Se connecter / S'inscrire"
    },
    "en": {
        "login": "Login / Register"
    }
}
</i18n>
