import { computed, ComputedRef } from 'vue'
import { useI18n } from 'vue-i18n'
import ProfileReferMenu from '@/components/partials/club/menu/ProfileReferMenu.vue'
import useAdminProfileNavigation from '@/libs/navigations/common/useAdminProfileNavigation'
import { ProfileNavigationItem } from '@/libs/navigations/useProfileNavigation'
import useGroupsQuery from '@/libs/queries/useGroupsQuery'
import { useAuthStore } from '@/libs/stores/auth.store'
import { useSegment } from '@/libs/vendors/segment/segment'
import { RoleName } from '@/vars/AuthAttr'
import { ClubRouteName, RouteName } from '@/vars/RouteName'

export default function useClubProfileNavigation(): ComputedRef<ProfileNavigationItem[]> {
    const authStore = useAuthStore()
    const adminProfileNavigation = useAdminProfileNavigation()
    const { analytics } = useSegment()

    const { data: myGroupsResults } = useGroupsQuery({ isJoined: true })

    const myCircles = computed(() => myGroupsResults.value?.data || [])

    const { t } = useI18n({
        messages: {
            fr: {
                'nav.profile': 'Mon profil',
                'nav.account': 'Mon compte',
                'nav.login_details': 'Mes informations personnelles',
                'nav.connections': 'Mes mises en relation',
                'nav.relationship_manager': 'Mon relationship manager',
                'nav.nft': 'Mes NFTs',
                'nav.circles': 'Mes cercles'
            }
        },
        useScope: 'global'
    })

    const accountSection = computed<ProfileNavigationItem[]>(() => {
        const accountChildren: ProfileNavigationItem[] = [
            {
                label: t('nav.profile'),
                to: authStore.hasRole(RoleName.LECLUB)
                    ? { name: ClubRouteName.MEMBER_PROFILE, params: { memberIdOrMe: 'me' } }
                    : { name: RouteName.MEMBERS_PROFIL, params: { memberId: 'me' } }
            },
            {
                label: t('nav.membership'),
                to: { name: RouteName.ACCOUNT_MEMBERSHIP }
            },
            {
                label: t('nav.login_details'),
                to: { name: RouteName.ACCOUNT_INFO }
            }
        ]

        if (authStore.hasRole(RoleName.LECLUB)) {
            accountChildren.push({ label: t('nav.connections'), to: { name: ClubRouteName.LISTING_MER } })
        }

        if (
            authStore.hasRole(RoleName.LECLUB) &&
            authStore.authentifiedUser &&
            authStore.authentifiedUser.prospect_admin
        ) {
            accountChildren.push({
                label: t('nav.relationship_manager'),
                href: `${import.meta.env.VITE_PRIVATE_BASE_URL}/inbox/new/${
                    authStore.authentifiedUser.prospect_admin.idff
                }`,
                action: () => {
                    analytics.track('leclub:rm')
                }
            })
        }

        if (authStore.hasRole(RoleName.LECLUB)) {
            accountChildren.push({
                label: t('nav.nft'),
                href: `${import.meta.env.VITE_PRIVATE_BASE_URL}/nft/home`
            })
        }

        return accountChildren
    })

    const nav = computed<ProfileNavigationItem[]>(() => {
        const items: ProfileNavigationItem[] = [...adminProfileNavigation.value]

        items.push({
            key: 'account',
            label: t('nav.account'),
            children: accountSection.value
        })

        items.push({
            component: ProfileReferMenu
        })

        if (myCircles.value.length > 0) {
            items.push({
                label: t('nav.circles'),
                children: myCircles.value.map((circle) => {
                    return {
                        label: circle.name,
                        to: { name: RouteName.CIRCLE_SINGLE, params: { slug: circle.slug } }
                    }
                })
            })
        }

        return items
    })

    return nav
}
