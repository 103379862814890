<script setup lang="ts">
    import { computed } from 'vue'
    import { useI18n } from 'vue-i18n'
    import FooterBottom from '@/components/layouts/network/footer-public/partials/FooterBottom.vue'
    import PublicClubHeader, { Link } from '@/components/layouts/public/PublicClubHeader.vue'
    import ContainerTemplate from '@/components/templates/ContainerTemplate.vue'
    import { AcademyRouteName, ClubRouteName, InvestRouteName } from '@/vars/RouteName'
    import { SpaName } from '@/vars/SpaAttr'

    defineProps<{
        asSlot?: boolean
    }>()

    const { t } = useI18n()

    const links = computed<Link[]>(() => {
        if (import.meta.env.VITE_SPA_RUNNING === SpaName.ACADEMY) {
            return [
                {
                    label: t('nav_academy.course'),
                    to: { name: AcademyRouteName.INTRODUCE_COURSE }
                }
            ]
        }

        if (import.meta.env.VITE_SPA_RUNNING === SpaName.INVEST) {
            return [
                {
                    label: t('nav_invest.invest'),
                    to: { name: InvestRouteName.LANDING_INVEST }
                },
                {
                    label: t('nav_invest.portfolio'),
                    to: { name: InvestRouteName.LANDING_PORTFOLIO }
                },
                {
                    label: t('nav_invest.team'),
                    to: { name: InvestRouteName.LANDING_TEAM }
                },
                {
                    separator: true
                },
                {
                    label: t('nav_invest.raise_funds'),
                    to: { name: InvestRouteName.LANDING_RAISE_FUNDS }
                }
            ]
        }

        return [
            {
                label: t('nav_header.membership'),
                to: { name: ClubRouteName.LANDING_MEMBERSHIP }
            },
            {
                label: t('nav_header.events'),
                to: { name: ClubRouteName.EVENTS_LISTING }
            },
            {
                label: t('nav_header.network'),
                to: { name: ClubRouteName.LANDING_COMMUNITY }
            },
            {
                label: t('nav_header.team'),
                to: { name: ClubRouteName.FF_TEAM }
            }
        ]
    })
</script>

<template>
    <div class="relative !bg-midnight">
        <PublicClubHeader :links="links" />
        <main>
            <slot v-if="asSlot" class="layout__content" />

            <RouterView v-else v-slot="{ Component: ViewComponent }" class="layout__router">
                <Transition name="page-">
                    <Component :is="ViewComponent" class="layout__content" />
                </Transition>
            </RouterView>
        </main>
        <ContainerTemplate public>
            <hr class="my-0 opacity-50" />
            <FooterBottom class="pb-10 pt-6 text-nuage" />
        </ContainerTemplate>
    </div>
</template>

<i18n lang="json">
{
    "fr": {
        "nav_academy": {
            "course": "Formation"
        },
        "nav_invest": {
            "invest": "Investir",
            "portfolio": "Portfolio",
            "team": "Équipe",
            "raise_funds": "Lever des fonds"
        },
        "nav": {
            "team": "Équipe"
        },
        "nav_header": {
            "network": "Membres & Entreprises",
            "membership": "Membership",
            "events": "Événements",
            "team": "Équipe",
            "apply": "Déposer ma candidature"
        }
    },
    "en": {
        "nav_academy": {
            "course": "Course"
        },
        "nav_invest": {
            "invest": "Invest",
            "portfolio": "Portfolio",
            "team": "Team",
            "raise_funds": "Raise funds"
        },
        "nav": {
            "team": "Team"
        },
        "nav_header": {
            "network": "Members & Companies",
            "membership": "Membership",
            "events": "Events",
            "team": "Team",
            "apply": "Apply"
        }
    }
}
</i18n>
