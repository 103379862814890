<script setup lang="ts">
    import { computed, ref } from 'vue'
    import { useI18n } from 'vue-i18n'
    import { RouteLocationNamedRaw } from 'vue-router'
    import { useEventListener } from '@vueuse/core'
    import AppSwitcher from '@/components/layouts/network/AppSwitcher.vue'
    import ClubProfilePill from '@/components/partials/public/ClubProfilePill.vue'
    import ContainerTemplate from '@/components/templates/ContainerTemplate.vue'
    import BaseButton from '@/components/ui/actions/BaseButton.v2.vue'
    import MaterialIcon from '@/components/ui/icons/MaterialIcon.vue'
    import LogoMedia from '@/components/ui/medias/LogoMedia.vue'
    import SpaRouterLink from '@/components/ui/navigations/SpaRouterLink.vue'
    import useBreakpoints from '@/libs/compositions/useBreakpoints'
    import { ButtonTheme } from '@/vars/ButtonAttr'
    import { AcademyRouteName, ClubRouteName, InvestRouteName } from '@/vars/RouteName'
    import { SpaName } from '@/vars/SpaAttr'

    export type Link = { label: string; to: RouteLocationNamedRaw } | { separator: true }

    defineProps<{
        links: Link[]
    }>()

    const currentSpa = import.meta.env.VITE_SPA_RUNNING

    const { t } = useI18n()

    const { isDesktop, breakpoints } = useBreakpoints()

    useEventListener(document, 'scroll', onScroll)

    const yScroll = ref(0)
    const isMobileMenuOpen = ref(false)

    const homeLink = computed(() => {
        switch (currentSpa) {
            case SpaName.ACADEMY:
                return { name: AcademyRouteName.HOME }
            case SpaName.INVEST:
                return { name: InvestRouteName.HOME }
            case SpaName.LECLUB:
                return { name: ClubRouteName.HOME }
            default:
                return ''
        }
    })

    const headerLabel = computed(() => {
        switch (currentSpa) {
            case SpaName.ACADEMY:
                return t('academy')
            case SpaName.INVEST:
                return t('invest')
            case SpaName.LECLUB:
                return t('club')
            default:
                return ''
        }
    })

    const hasScrolledScreen = computed(() => {
        return yScroll.value > 50
    })

    function onScroll() {
        yScroll.value = window.scrollY
    }
</script>

<template>
    <ContainerTemplate tag="header" class="header fixed left-1/2 top-6 z-20 -translate-x-1/2 !py-0" public>
        <div class="header__content relative flex items-center justify-between rounded-xl px-6 py-4">
            <div class="rounded-xl blur"></div>
            <SpaRouterLink :to="homeLink" class="inline-flex min-h-7 items-stretch gap-6">
                <LogoMedia class="h-6" short />
                <Transition name="fade-" mode="out-in">
                    <div v-if="hasScrolledScreen && !breakpoints.xs" class="inline-flex gap-6">
                        <div class="w-px bg-nuage"></div>
                        <div class="text-lg font-medium text-white">
                            {{ headerLabel }}
                        </div>
                    </div>
                </Transition>
            </SpaRouterLink>
            <div v-if="isDesktop" class="flex items-center gap-8">
                <nav class="flex items-center gap-6 text-sm text-white">
                    <template v-for="(link, i) in links" :key="i">
                        <SpaRouterLink
                            v-if="'to' in link"
                            :to="link.to"
                            class="border-b border-transparent py-2"
                            active-class="!border-royale"
                        >
                            {{ link.label }}
                        </SpaRouterLink>
                        <span
                            v-else-if="'separator' in link && link.separator"
                            class="inline-block h-4 w-[2px] bg-white"
                        ></span>
                    </template>
                </nav>
                <BaseButton
                    v-if="currentSpa === SpaName.ACADEMY"
                    :theme="ButtonTheme.GHOST"
                    :class="{ '!bg-nuage !text-legacy': yScroll > 400 }"
                    :to="{ name: AcademyRouteName.PRE_REGISTER }"
                >
                    {{ t('apply_academy') }}
                </BaseButton>
                <BaseButton
                    v-else-if="currentSpa === SpaName.LENETWORK"
                    :theme="ButtonTheme.GHOST"
                    :class="{ '!bg-nuage !text-legacy': yScroll > 400 }"
                    :to="{ name: ClubRouteName.APPLY_CLUB }"
                >
                    {{ t('candidate') }}
                </BaseButton>
                <AppSwitcher theme="glass" />
                <ClubProfilePill />
            </div>
            <div v-else-if="links?.length">
                <AppSwitcher theme="glass" class="mr-10" />
                <button type="button" @click="isMobileMenuOpen = true">
                    <MaterialIcon class="text-3xl text-white" name="menu" />
                </button>
                <Teleport to="body">
                    <Transition name="fadeRight-">
                        <div
                            v-if="isMobileMenuOpen"
                            class="fixed left-0 top-0 z-50 h-screen w-screen overflow-auto bg-legacy"
                        >
                            <div class="relative">
                                <button type="button" class="absolute right-2 top-6" @click="isMobileMenuOpen = false">
                                    <MaterialIcon class="text-3xl text-white" name="close" />
                                </button>
                                <div class="p-4">
                                    <LogoMedia />
                                    <hr class="my-6 opacity-30" />
                                    <nav class="flex flex-col items-start gap-6 text-white">
                                        <template v-for="(link, i) in links" :key="i">
                                            <SpaRouterLink
                                                v-if="'to' in link"
                                                :to="link.to"
                                                class="border-b border-transparent py-2"
                                                active-class="!border-royale"
                                                @click="isMobileMenuOpen = false"
                                            >
                                                {{ link.label }}
                                            </SpaRouterLink>
                                            <span
                                                v-else-if="'separator' in link && link.separator"
                                                class="inline-block h-px w-full bg-white/25"
                                            ></span>
                                        </template>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </Transition>
                </Teleport>
            </div>
        </div>
    </ContainerTemplate>
</template>

<style lang="scss" scoped>
    .blur {
        @apply absolute inset-0 -z-10;
        backdrop-filter: blur(1rem);
    }

    .header {
        @apply z-50;
        &__content {
            background: rgba(var(--theme-color-legacy--rgb), 0.25);
        }
    }

    .fadeRight {
        &--enter-active,
        &--leave-active {
            transition: all 500ms cubic-bezier(0.25, 0.69, 0.21, 1);
        }

        &--enter-from,
        &--leave-to {
            transform: translateX(100%);
        }
    }
</style>

<i18n lang="json">
{
    "fr": {
        "club": "Club privé",
        "academy": "Academy",
        "invest": "Investir",
        "candidate": "Déposer ma candidature",
        "apply_academy": "Postuler maintenant",
        "raise_funds": "Lever des fonds",
        "nav_header": {
            "network": "Membres & Entreprises",
            "membership": "Expérience",
            "events": "Événements",
            "team": "Équipe",
            "apply": "Déposer ma candidature"
        }
    },
    "en": {
        "club": "Private Club",
        "academy": "Academy",
        "invest": "Invest",
        "candidate": "Submit my application",
        "apply_academy": "Apply now",
        "raise_funds": "Raise funds",
        "nav": {
            "network": "Members & Companies",
            "membership": "Experience",
            "events": "Events",
            "team": "Team",
            "apply": "Submit my application",
            "apply_academy": "Postuler maintenant"
        }
    }
}
</i18n>
