<script setup lang="ts">
    import { computed } from 'vue'
    import LogoMedia from '@/components/ui/medias/LogoMedia.vue'
    import SpaRouterLink from '@/components/ui/navigations/SpaRouterLink.vue'
    import { AcademyRouteName, ClubRouteName, InvestRouteName, ProRouteName, RouteName } from '@/vars/RouteName'
    import { SpaName } from '@/vars/SpaAttr'

    const currentSpa = import.meta.env.VITE_SPA_RUNNING

    const props = defineProps<{
        isMobile?: boolean
    }>()

    const to = computed(() => {
        switch (currentSpa) {
            case SpaName.LENETWORK:
                return { name: RouteName.HOME }
            case SpaName.PRO:
                return { name: ProRouteName.HOME }
            case SpaName.LECLUB:
                return { name: ClubRouteName.HOME }
            case SpaName.ACADEMY:
                return { name: AcademyRouteName.HOME }
            case SpaName.INVEST:
                return { name: InvestRouteName.HOME }
            default:
                throw new Error(`Missing "${currentSpa}" home link`)
        }
    })

    const logoClass = computed(() => {
        if (currentSpa === SpaName.LENETWORK) {
            return props.isMobile ? 'w-32' : 'w-24'
        }

        return 'h-8'
    })

    const label = computed(() => {
        switch (currentSpa) {
            case SpaName.PRO:
                return `Business\nSolutions`
            case SpaName.LECLUB:
                return `Club privé`
            case SpaName.ACADEMY:
                return `Academy`
            case SpaName.INVEST:
                return `Invest`
            default:
                return ''
        }
    })
</script>

<template>
    <SpaRouterLink :to="to" class="flex items-center gap-4" :class="isMobile ? 'pb-10 pt-8' : ''">
        <LogoMedia :class="logoClass" :dark="currentSpa !== SpaName.INVEST" :short="currentSpa !== SpaName.LENETWORK" />
        <template v-if="currentSpa !== SpaName.LENETWORK">
            <div class="h-8 w-px" :class="currentSpa === SpaName.INVEST ? `bg-white` : `bg-legacy`"></div>
            <div v-white-space class="text-sm font-medium">{{ label }}</div>
        </template>
    </SpaRouterLink>
</template>
