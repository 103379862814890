<script setup lang="ts">
    import { computed } from 'vue'
    import ContainerTemplate from '@/components/templates/ContainerTemplate.vue'
    import MaterialIcon from '@/components/ui/icons/MaterialIcon.vue'
    import IndicatorBadge from '@/components/ui/indicators/IndicatorBadge.vue'
    import PlateformLogoNavigation from '@/components/ui/navigations/PlateformLogoNavigation.vue'
    import SpaRouterLink from '@/components/ui/navigations/SpaRouterLink.vue'
    import useProfileNavigation from '@/libs/navigations/useProfileNavigation'
    import useEkkoUnreadMessageCountQuery from '@/libs/queries/useEkkoUnreadMessageCountQuery'
    import { useAuthStore } from '@/libs/stores/auth.store'
    import { InboxRouteName } from '@/vars/RouteName'
    import HeaderNavigationProvider from './navigations/HeaderNavigationProvider.vue'
    import HeaderMainNavigation from './navigations/main/HeaderMainNavigation.vue'
    import HeaderMainNavigationMobile from './navigations/main/HeaderMainNavigationMobile.vue'
    import HeaderProfileNavigation from './navigations/profile/HeaderProfileNavigation.vue'

    defineProps<{
        hideMenu?: boolean
    }>()

    const authStore = useAuthStore()
    const profileNavigation = useProfileNavigation()

    const isLogin = computed(() => {
        return authStore.isLoggedIn
    })

    const { data: unreadCount } = useEkkoUnreadMessageCountQuery({
        staleTime: 0
    })
</script>

<template>
    <header
        class="header"
        :class="{
            'header--anonymous': !isLogin
        }"
    >
        <ContainerTemplate class="header__container">
            <HeaderNavigationProvider>
                <template #default="{ navigationItems }">
                    <HeaderMainNavigationMobile
                        :navigation="navigationItems"
                        :profile="profileNavigation"
                        class="header__navigation header__navigation--mobile"
                    />

                    <div class="flex-1">
                        <div class="flex items-center justify-between lg:mb-2 lg:pt-4">
                            <PlateformLogoNavigation dark />

                            <HeaderMainNavigation
                                v-if="!hideMenu"
                                :navigation="navigationItems"
                                class="header__navigation header__navigation--desktop lg:ml-10"
                            />
                            <HeaderProfileNavigation :profile="profileNavigation">
                                <template v-if="isLogin" #controls>
                                    <div v-if="authStore.authentifiedUser?.ekko_access_token" class="controls">
                                        <SpaRouterLink :to="{ name: InboxRouteName.INBOX }" class="controls__item">
                                            <MaterialIcon name="mail" class="controls__icon" />
                                            <IndicatorBadge
                                                v-if="unreadCount"
                                                class="controls__indicator"
                                                :count="unreadCount"
                                                :max-count="9"
                                            />
                                        </SpaRouterLink>
                                    </div>
                                </template>
                            </HeaderProfileNavigation>
                        </div>
                    </div>
                </template>
            </HeaderNavigationProvider>
        </ContainerTemplate>
    </header>
</template>

<style scoped lang="scss">
    @import '@/libs/sass/vars';
    .header {
        position: relative;
        height: var(--header-height--mobile);
        display: flex;
        justify-content: center;
        box-shadow: 0 0 0.1875em var(--theme-shadow-color);
        z-index: 100;

        @media screen and (min-width: $breakpoints-lg) {
            height: var(--header-height);
            justify-content: space-between;
            align-items: flex-start;
        }

        &--anonymous {
            background-color: var(--theme-background-color);
            color: var(--theme-text-color);
        }

        &__container {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &__logo {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: var(--scale-2);
            @media only screen and (min-width: $breakpoints-lg) {
                margin-right: var(--scale-4);
            }
        }
        &__logo-bar {
            width: var(--scale-px);
            height: 100%;
            background-color: var(--theme-color-legacy);
            margin-inline: var(--scale-2);
        }

        &__navigation {
            &--mobile {
                display: block;
                margin-right: var(--scale-4);

                @media only screen and (min-width: $breakpoints-lg) {
                    display: none;
                }
            }
            &--desktop {
                display: none;
                @media only screen and (min-width: $breakpoints-lg) {
                    display: block;
                    flex-grow: 1;
                }
            }
        }
    }
    .controls {
        position: relative;
        z-index: 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-right: var(--scale-2);

        &__item {
            position: relative;
            z-index: 1;
            color: var(--theme-text-color--header);
            display: flex;
            margin: 0 var(--scale-2);

            &--desktop {
                display: none;

                @media only screen and (min-width: $breakpoints-lg) {
                    display: block;
                }
            }
        }

        &__indicator {
            position: absolute;
            top: -0.25em;
            right: -0.75em;
        }

        &__icon {
            font-size: 1.5em;

            &--search {
                cursor: pointer;
            }
        }
    }
</style>
