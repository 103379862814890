import type { RouteRecordRaw } from 'vue-router'
import EmptyLayout from '@/components/layouts/EmptyLayout.vue'
import PublicClubLayout from '@/components/layouts/public/PublicClubLayout.vue'
import { useAuthStore } from '@/libs/stores/auth.store'
import eventsRoutes from '@/routes/common/events-routes'
import { RoleName } from '@/vars/AuthAttr'
import { InvestRouteName } from '@/vars/RouteName'

const routes: RouteRecordRaw[] = [
    ...eventsRoutes,
    {
        path: '/',
        component: () => {
            const authStore = useAuthStore()

            if (authStore.hasRole(RoleName.INVESTORSCLUB)) {
                return import('@/views/invest/home/InvestHome.vue')
            }

            return import('@/views/invest/landing/home/InvestLandingHome.vue')
        },
        meta: {
            layout: () => {
                const authStore = useAuthStore()

                return authStore.hasRole(RoleName.INVESTORSCLUB) ? undefined : PublicClubLayout
            }
        },
        children: [
            {
                name: InvestRouteName.HOME,
                path: '',
                component: () => import('@/views/invest/home/tabs/ClubHome.vue'),
                meta: {
                    layoutProps: {
                        extended: 'sm'
                    }
                }
            },
            {
                name: InvestRouteName.CHAT,
                path: 'discussions',
                component: () => import('@/views/invest/home/tabs/ClubChatRoom.vue'),
                meta: {
                    layoutProps: {
                        extended: 'sm'
                    }
                }
            },
            {
                name: InvestRouteName.WHATSAPP,
                path: 'whatsapp',
                component: () => import('@/views/invest/home/tabs/ClubWhatsapp.vue'),
                meta: {
                    layoutProps: {
                        extended: 'sm'
                    }
                }
            },
            {
                name: InvestRouteName.MEMBERS,
                path: 'members',
                component: () => import('@/views/invest/home/tabs/ClubMembersList.vue'),
                meta: {
                    layoutProps: {
                        extended: 'sm'
                    }
                }
            },
            {
                name: InvestRouteName.PROJECTS,
                path: 'projects',
                component: () => import('@/views/invest/home/tabs/ClubProjectList.vue'),
                meta: {
                    layoutProps: {
                        extended: 'sm'
                    }
                }
            },
            {
                name: InvestRouteName.EVENTS,
                path: 'events',
                component: () => import('@/views/invest/home/tabs/ClubEvents.vue'),
                meta: {
                    layoutProps: {
                        extended: 'sm'
                    }
                }
            },
            {
                name: InvestRouteName.NEWS,
                path: 'articles',
                component: () => import('@/views/invest/home/tabs/articles/ClubArticle.vue'),
                meta: {
                    layoutProps: {
                        extended: 'sm'
                    }
                }
            },
            {
                name: InvestRouteName.RULES,
                path: 'rules',
                component: () => import('@/views/invest/home/tabs/ClubRules.vue'),
                meta: {
                    layoutProps: {
                        extended: 'sm'
                    }
                }
            },
            {
                name: InvestRouteName.VIDEOS,
                path: 'videos',
                component: () => import('@/views/invest/home/tabs/videos/ClubVideos.vue'),
                meta: {
                    layoutProps: {
                        extended: 'sm'
                    }
                }
            }
        ]
    },
    {
        name: InvestRouteName.LANDING_INVESTOR_CLUB,
        path: '/investor-club',
        component: () => import('@/views/invest/landing/investors-club/JoinInvestPage.vue'),
        meta: {
            layout: PublicClubLayout
        }
    },
    {
        name: InvestRouteName.LANDING_INVEST,
        path: '/investment',
        component: () => import('@/views/invest/landing/invest/InvestIntroduction.vue'),
        meta: {
            layout: PublicClubLayout
        }
    },
    {
        name: InvestRouteName.JOIN_INVESTOR_CLUB,
        path: '/join-investors-club',
        component: () => import('@/views/invest/join/InvestorsClubJoin.vue'),
        meta: {
            layout: PublicClubLayout
        }
    },
    {
        name: InvestRouteName.LANDING_TEAM,
        path: '/team',
        component: () => import('@/views/invest/landing/team/TeamPage.vue'),
        meta: {
            layout: PublicClubLayout
        }
    },
    {
        name: InvestRouteName.LANDING_RAISE_FUNDS,
        path: '/raise-funds',
        component: () => import('@/views/invest/landing/raise-funds/RaiseFundsPage.vue'),
        meta: {
            layout: PublicClubLayout
        }
    },
    {
        name: InvestRouteName.LANDING_PORTFOLIO,
        path: '/portfolio',
        component: () => import('@/views/invest/landing/portfolio/PortfolioPage.vue'),
        meta: {
            layout: PublicClubLayout
        }
    },
    {
        name: InvestRouteName.LANDING_LE_FONDS,
        path: '/le-fonds',
        component: () => import('@/views/invest/landing/le-fonds/LeFondsIntroductionPage.vue'),
        meta: {
            layout: PublicClubLayout
        }
    },
    {
        path: '/projects/:project',
        name: InvestRouteName.PROJECT_DETAILS,
        component: () => import('@/views/invest/project/details/ProjectDetails.vue')
    },
    {
        path: '/apply',
        name: InvestRouteName.APPLY,
        component: () => import('@/views/applies/leclub/ApplyLeClub.vue'),
        meta: {
            layout: EmptyLayout
        }
    },
    {
        path: '/apply/finished',
        name: InvestRouteName.APPLY_FINISHED,
        component: () => import('@/views/invest/apply/investor-club/finished/InvestorApplyFinished.vue'),
        meta: {
            layout: EmptyLayout
        }
    }
]

export default routes
