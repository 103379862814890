import { computed, ComputedRef } from 'vue'
import { useI18n } from 'vue-i18n'
import { ProfileNavigationItem } from '@/libs/navigations/useProfileNavigation'
import { useAuthStore } from '@/libs/stores/auth.store'
import { RoleName } from '@/vars/AuthAttr'

export default function useAdminProfileNavigation(): ComputedRef<ProfileNavigationItem[]> {
    const authStore = useAuthStore()

    const { t } = useI18n({
        messages: {
            fr: {
                nav: {
                    admin: 'Admin',
                    admin_dashboard: 'Admin dashboard'
                }
            },
            en: {
                nav: {
                    admin: 'Admin',
                    admin_dashboard: 'Admin dashboard'
                }
            }
        },
        useScope: 'global'
    })

    const adminNav = computed<ProfileNavigationItem[]>(() => {
        const items: ProfileNavigationItem[] = []

        if (authStore.hasRole(RoleName.ADMIN)) {
            items.push({
                key: 'admin',
                label: t('nav.admin'),
                children: [
                    {
                        label: t('nav.admin_dashboard'),
                        href: `${import.meta.env.VITE_ADMIN_BASE_URL}/dashboard`
                    }
                ]
            })

            if (authStore.isOnSwitchTo) {
                items.push({
                    children: [
                        {
                            label: t('nav.back_admin_user'),
                            action: () => {
                                authStore.exitSwitchToUser()
                            }
                        }
                    ]
                })
            }
        }

        return items
    })

    return adminNav
}
