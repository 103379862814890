import { defineStore } from 'pinia'
import { AddonKey } from '@/types/response/apply/ApplyConfigurationResponse.type'
import { ClubRouteName } from '@/vars/RouteName'
import { StorageKey } from '@/vars/StorageAttr'

export type AddonFrequency = 'monthly' | 'yearly'

type ClubCart = {
    addonsFrequency: AddonFrequency
    city?: string
    addon: {
        [key in AddonKey]: boolean
    }
}

type State = {
    inMaintenance: boolean
    cart: {
        club: ClubCart
    }
}

export const useAppStore = defineStore('app', {
    state: (): State => {
        let clubCart: ClubCart = {
            addonsFrequency:
                (window.localStorage.getItem(StorageKey.CLUB_CART_FREQUENCY) as AddonFrequency) || 'monthly',
            city: 'New York',
            addon: {
                worldwide: false,
                flp: false,
                ic: false,
                benefit: false
            }
        }

        try {
            const cartFromStorage = window.localStorage.getItem(StorageKey.CLUB_CART)

            if (cartFromStorage) {
                clubCart = JSON.parse(cartFromStorage) as ClubCart
            }
        } catch {
            // Ignore silently
        }

        return {
            inMaintenance: false,
            cart: {
                club: clubCart
            }
        }
    },
    actions: {
        modifyClubCart(cartPart: Partial<ClubCart>) {
            this.cart.club = { ...this.cart.club, ...cartPart }
            window.localStorage.setItem(StorageKey.CLUB_CART, JSON.stringify(this.cart.club))
        },
        toggleAddon(addonKey: AddonKey, value?: boolean) {
            this.cart.club.addon[addonKey] = value ?? !this.cart.club.addon[addonKey]
            window.localStorage.setItem(StorageKey.CLUB_CART, JSON.stringify(this.cart.club))
        }
    },
    getters: {
        clubApplyLink: (state) => {
            return {
                name: ClubRouteName.APPLY_CLUB,
                query: {
                    addonsFrequency: state.cart.club.addonsFrequency || 'yearly',
                    city: state.cart.club.city || 'New York',
                    addonsSelected: Object.entries(state.cart.club.addon || {})
                        .reduce<string[]>((addonKeyList, [addonKey, isSelected]) => {
                            if (isSelected) {
                                addonKeyList.push(addonKey)
                            }

                            return addonKeyList
                        }, [])
                        .join(',')
                }
            }
        }
    }
})
