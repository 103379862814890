import { computed, ComputedRef } from 'vue'
import { useI18n } from 'vue-i18n'
import useAccountProfileNavigation from '@/libs/navigations/common/useAccountProfileNavigation'
import useAdminProfileNavigation from '@/libs/navigations/common/useAdminProfileNavigation'
import { ProfileNavigationItem } from '@/libs/navigations/useProfileNavigation'
import { useTeamStore } from '@/libs/stores/team.store'
import { ProRouteName } from '@/vars/RouteName'

export default function useBusinessSolutionsProfileNavigation(): ComputedRef<ProfileNavigationItem[]> {
    const teamStore = useTeamStore()
    const adminProfileNavigation = useAdminProfileNavigation()
    const accountProfileNavigation = useAccountProfileNavigation()

    const { t } = useI18n({
        messages: {
            fr: {
                'nav.company': 'Mon entreprise',
                'nav.home': 'Accueil',
                'nav.company_settings': 'Paramètres entreprise'
            },
            en: {
                'nav.company': 'My company',
                'nav.home': 'Home',
                'nav.company_settings': 'Company settings'
            }
        },
        useScope: 'global'
    })

    const nav = computed<ProfileNavigationItem[]>(() => {
        const items: ProfileNavigationItem[] = [
            ...adminProfileNavigation.value,
            {
                label: teamStore.myTeam?.company?.name || t('nav.company'),
                icon: teamStore.myTeam?.company?.logo || undefined,
                children: [
                    {
                        label: t('nav.company_settings'),
                        to: { name: ProRouteName.MY_COMPANY_PROFILE }
                    }
                ]
            },
            ...accountProfileNavigation.value
        ]

        return items
    })

    return nav
}
