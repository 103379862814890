import { ComponentInstance, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { RouteLocationNamedRaw, RouteParams } from 'vue-router'
import useDefaultProfileNavigation from '@/libs/navigations/common/useDefaultProfileNavigation'
import useClubProfileNavigation from '@/libs/navigations/leclub/useClubProfileNavigation'
import useNetworkProfileNavigation from '@/libs/navigations/network/useNetworkProfileNavigation'
import useBusinessSolutionsProfileNavigation from '@/libs/navigations/pro/useBusinessSolutionsProfileNavigation'
import { useAuthStore } from '@/libs/stores/auth.store'
import { SpaName } from '@/vars/SpaAttr'

export type ProfileNavigationItem =
    | { component: ComponentInstance<any> } // eslint-disable-line
    | {
          key?: string
          icon?: string
          label?: string
          subtitle?: string
          disabled?: boolean
          to?: RouteParams | RouteLocationNamedRaw
          href?: string
          target?: string
          action?: () => void
          children?: ProfileNavigationItem[]
      }

/**
 * Liste des entrées présentes dans la navigation "pilule"
 */
export default function useProfileNavigation() {
    const authStore = useAuthStore()
    const defaultProfileNavigation = useDefaultProfileNavigation()
    const clubProfileNavigation = useClubProfileNavigation()
    const bsProfileNavigation = useBusinessSolutionsProfileNavigation()
    const networkProfileNavigation = useNetworkProfileNavigation()

    const { t } = useI18n({
        messages: {
            fr: {
                'nav.logout': 'Déconnexion',
                'nav.back_admin_user': 'Retour à mon compte'
            },
            en: {
                'nav.logout': 'Logout',
                'nav.back_admin_user': 'Back to my account'
            }
        }
    })

    const nav = computed<ProfileNavigationItem[]>(() => {
        switch (import.meta.env.VITE_SPA_RUNNING) {
            case SpaName.LECLUB:
                return clubProfileNavigation.value
            case SpaName.PRO:
                return bsProfileNavigation.value
            case SpaName.LENETWORK:
                return networkProfileNavigation.value
            default:
                return defaultProfileNavigation.value
        }
    })

    const navWithLogout = computed(() => {
        const finalNavigation = [...nav.value]

        if (authStore.isLoggedIn) {
            finalNavigation.push({
                children: [
                    {
                        label: t('nav.logout'),
                        action() {
                            authStore.logout()
                        }
                    }
                ]
            })
        }

        return finalNavigation
    })

    return navWithLogout
}
