import { loadScript } from '@/libs/helpers/load-scripts'
import { User } from '@/types/Auth.type'
import { BusinessLineName } from '@/vars/AuthAttr'

export function initGoogleAnalytics(googleAnalyticsId?: string) {
    if (!googleAnalyticsId) {
        return null
    }

    loadScript(`https://www.googletagmanager.com/gtm.js?id=${googleAnalyticsId}`, false, { async: true })

    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
}

export function authenticateUserInGoogleAnalytics(member: User) {
    if (window.dataLayer) {
        window.dataLayer.push({
            event: 'authentication',
            user_id: member.idff,
            first_name: member.first_name,
            last_name: member.last_name,
            email: member.email,
            position: member.position,
            is_admin: member.is_admin,
            is_club: member.business_lines?.includes(BusinessLineName.LECLUB),
            business_lines: member.business_lines,
            company: member.company?.name,
            picture: member.picture,
            rm: member?.prospect_admin
                ? {
                      first_name: member.prospect_admin.first_name,
                      full_name: member.prospect_admin.full_name,
                      picture: member.prospect_admin.picture
                  }
                : null
        })
    }
}

export function signupUserInGoogleAnalytics(memberId: string, from?: string) {
    if (window.dataLayer) {
        window.dataLayer.push({
            event: 'sign_up',
            user_id: memberId,
            from: from
        })
    }
}

export function bookMeetingInGoogleAnalytics(city: string) {
    if (window.dataLayer) {
        window.dataLayer.push({
            event: 'book_meeting',
            city: city
        })
    }
}

export function applyFinishedInGoogleAnalytics(amount: number, currency: string) {
    if (window.dataLayer) {
        window.dataLayer.push({
            event: 'apply_finished',
            amount: amount,
            currency: currency
        })
    }
}
