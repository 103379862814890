<script lang="ts" setup>
    import { onMounted } from 'vue'
    import { useI18n } from 'vue-i18n'
    import HeaderProfileNavigation from '@/components/layouts/leclub/header/navigations/profile/HeaderProfileNavigation.vue'
    import MaterialIcon from '@/components/ui/icons/MaterialIcon.vue'
    import AvatarMedia from '@/components/ui/medias/AvatarMedia.vue'
    import PopoverContent from '@/components/ui/navigations/PopoverContent.vue'
    import SpaRouterLink from '@/components/ui/navigations/SpaRouterLink.vue'
    import CardBody from '@/components/ui/wrappers/cards/CardBody.vue'
    import CardWrapper from '@/components/ui/wrappers/cards/CardWrapper.vue'
    import useProfileNavigation from '@/libs/navigations/useProfileNavigation'
    import { useAuthStore } from '@/libs/stores/auth.store'
    import { RoleName } from '@/vars/AuthAttr'
    import { AvatarSize } from '@/vars/AvatarAttr'
    import { RouteName } from '@/vars/RouteName'

    const { t } = useI18n()
    const authStore = useAuthStore()
    // const { items: clubProfileNavigation } = getClubProfileNavigationItems()
    const profileNavigation = useProfileNavigation()

    onMounted(() => {
        authStore.getMe()
    })
</script>

<template>
    <PopoverContent>
        <template #action>
            <HeaderProfileNavigation
                v-if="authStore.hasRole(RoleName.LECLUB)"
                :profile="profileNavigation"
                hide-controls
            />
            <AvatarMedia
                v-else-if="authStore.authentifiedUser?.picture"
                :src="authStore.authentifiedUser.picture"
                :size="AvatarSize.MINI"
            />
            <MaterialIcon v-else name="account_circle" class="relative top-0.5 text-3xl text-nuage" />
        </template>
        <template v-if="!authStore.hasRole(RoleName.LECLUB)" #default>
            <CardWrapper>
                <CardBody class="flex flex-col gap-2 text-sm font-medium">
                    <template v-if="authStore.authentifiedUser?.picture">
                        <SpaRouterLink :to="{ name: RouteName.ACCOUNT_HOME }">{{ t('my_account') }}</SpaRouterLink>
                        <SpaRouterLink :to="{ name: RouteName.LOGOUT }">{{ t('logout') }}</SpaRouterLink>
                    </template>
                    <template v-else>
                        <SpaRouterLink :to="{ name: RouteName.LOGIN }">{{ t('create_account') }}</SpaRouterLink>
                        <SpaRouterLink :to="{ name: RouteName.LOGIN }">{{ t('login') }}</SpaRouterLink>
                    </template>
                </CardBody>
            </CardWrapper>
        </template>
    </PopoverContent>
</template>

<i18n lang="json">
{
    "fr": {
        "my_account": "Mon compte",
        "logout": "Se déconnecter",
        "login": "Se connecter",
        "create_account": "Créer un compte"
    },
    "en": {
        "my_account": "My account",
        "logout": "Logout",
        "login": "Login",
        "create_account": "Create account"
    }
}
</i18n>
