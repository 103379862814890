<script setup lang="ts">
    import { computed, inject, Ref, ref, watch } from 'vue'
    import { useI18n } from 'vue-i18n'
    import { useRoute } from 'vue-router'
    import { vIntersectionObserver } from '@vueuse/components'
    import gsap from 'gsap'
    import BaseIcon from '@/components/ui/basics/BaseIcon.vue'
    import SpaRouterLink from '@/components/ui/navigations/SpaRouterLink.vue'
    import { useNavigationResolver } from '@/libs/compositions/useNavigationResolver'
    import { useSegment } from '@/libs/vendors/segment/segment'
    import { AboutRouteName, InvestRouteName } from '@/vars/RouteName'
    import { SpaName } from '@/vars/SpaAttr'
    import { StorageKey } from '@/vars/StorageAttr'

    const currentSpa = import.meta.env.VITE_SPA_RUNNING

    const showCookiesConsent = inject<Ref<boolean>>('showCookiesConsent')
    const { locale } = useI18n({ useScope: 'global' })
    const { t } = useI18n()
    const route = useRoute()
    const { analytics } = useSegment()
    const { redirectBounding } = useNavigationResolver()
    const hasTriggeredAnimation = ref(false)

    const lang = computed({
        get: () => {
            return locale.value
        },
        set: (newLang: string) => {
            locale.value = newLang
            window.localStorage.setItem(StorageKey.LOCALE, newLang)
        }
    })

    watch(lang, (newLang) => {
        window.location.reload()

        if (
            [
                InvestRouteName.LANDING_INVEST,
                InvestRouteName.LANDING_LE_FONDS,
                InvestRouteName.LANDING_PORTFOLIO,
                InvestRouteName.LANDING_TEAM,
                InvestRouteName.LANDING_RAISE_FUNDS,
                InvestRouteName.JOIN_INVESTOR_CLUB
                // @ts-expect-error Valide mais il n'aime pas l'énumération
            ].includes(route.name)
        )
            analytics.track('invest:landing:language:switched', { lang: newLang })
    })

    function onSectionVisible([{ isIntersecting }]: IntersectionObserverEntry[]) {
        if (isIntersecting && !hasTriggeredAnimation.value) {
            hasTriggeredAnimation.value = true
            gsap.from('[data-animate-bottom-links]', {
                x: 30,
                opacity: 0,
                ease: 'power2.quad',
                stagger: 0.1
            })
        }
    }

    function onManageCookies() {
        if (showCookiesConsent) {
            showCookiesConsent.value = true
        }
    }

    function getRedirectionBinding(route: { name: string }) {
        return redirectBounding(route, { spa: SpaName.LENETWORK }).to
            ? { to: redirectBounding(route, { spa: SpaName.LENETWORK }).to }
            : { href: redirectBounding(route, { spa: SpaName.LENETWORK }).href }
    }
</script>

<template>
    <div v-intersection-observer="onSectionVisible">
        <div class="flex flex-col items-start gap-10 md:flex-row lg:items-center">
            <div v-white-space class="text-sm">{{ t('copyright', { year: new Date().getFullYear() }) }}</div>
            <nav class="w-full flex-1 @container">
                <ul class="flex flex-wrap gap-4 text-xs underline">
                    <li data-animate-bottom-links>
                        <SpaRouterLink v-bind="getRedirectionBinding({ name: AboutRouteName.WEWEB_CGV })">
                            {{ t('legal') }}
                        </SpaRouterLink>
                    </li>
                    <li data-animate-bottom-links>
                        <SpaRouterLink v-bind="getRedirectionBinding({ name: AboutRouteName.WEWEB_CGV })">
                            {{ t('terms') }}
                        </SpaRouterLink>
                    </li>
                    <li data-animate-bottom-links>
                        <SpaRouterLink v-bind="getRedirectionBinding({ name: AboutRouteName.WEWEB_CGV_ENTREPRISE })">
                            {{ t('business_terms') }}
                        </SpaRouterLink>
                    </li>
                    <li data-animate-bottom-links>
                        <SpaRouterLink v-bind="getRedirectionBinding({ name: AboutRouteName.WEWEB_PRIVACY })">
                            {{ t('privacy_policy') }}
                        </SpaRouterLink>
                    </li>
                    <li data-animate-bottom-links>
                        <SpaRouterLink v-bind="getRedirectionBinding({ name: AboutRouteName.WEWEB_FR_COOKIES })">
                            {{ t('cookie_policy') }}
                        </SpaRouterLink>
                    </li>
                    <li data-animate-bottom-links>
                        <a class="cursor-pointer" @click="onManageCookies">
                            {{ t('manage_cookies') }}
                        </a>
                    </li>
                </ul>
            </nav>

            <nav class="flex items-center justify-end gap-4">
                <a
                    class="flex"
                    data-animate-bottom-links
                    href="//www.linkedin.com/company/frenchfounders/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <BaseIcon name="linkedin2" />
                </a>
                <a
                    class="flex"
                    data-animate-bottom-links
                    href="//www.instagram.com/frenchfounders_inc/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <BaseIcon name="instagram" />
                </a>
                <a
                    class="flex"
                    data-animate-bottom-links
                    href="//x.com/frenchfounders"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <BaseIcon name="x" />
                </a>
                <a
                    class="flex"
                    data-animate-bottom-links
                    href="//www.facebook.com/Frenchfounders"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <BaseIcon name="facebook" />
                </a>
            </nav>
        </div>
        <select
            v-if="currentSpa === SpaName.INVEST"
            v-model="lang"
            class="mt-4r rounded border-0 bg-legacy text-xs text-white"
        >
            <option value="fr">🇫🇷 Français</option>
            <option value="en">🇬🇧 English</option>
        </select>
    </div>
</template>

<i18n lang="json">
{
    "fr": {
        "copyright": "© {year} Frenchfounders.\nTous droits réservés",
        "legal": "Mentions légales",
        "terms": "CGU",
        "business_terms": "CGU Entreprises",
        "privacy_policy": "Politique de confidentialité",
        "cookie_policy": "Politique de cookies",
        "manage_cookies": "Gérer mes cookies"
    },
    "en": {
        "copyright": "© {year} Frenchfounders. All rights reserved",
        "legal": "Legal informations",
        "terms": "Terms",
        "business_terms": "Business terms",
        "privacy_policy": "Privacy Policy",
        "cookie_policy": "Cookies policy",
        "manage_cookies": "Manage cookies"
    }
}
</i18n>
