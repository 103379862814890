<script setup lang="ts">
    import { ref } from 'vue'
    import { useI18n } from 'vue-i18n'
    import { vIntersectionObserver } from '@vueuse/components'
    import gsap from 'gsap'
    import BaseIcon from '@/components/ui/basics/BaseIcon.vue'
    import SpaRouterLink from '@/components/ui/navigations/SpaRouterLink.vue'
    import { AcademyRouteName } from '@/vars/RouteName'

    const { t } = useI18n()
    const hasTriggeredAnimation = ref(false)

    function onSectionVisible([{ isIntersecting }]: IntersectionObserverEntry[]) {
        if (isIntersecting && !hasTriggeredAnimation.value) {
            hasTriggeredAnimation.value = true
            gsap.from('[data-animate-bottom-links]', {
                x: 30,
                opacity: 0,
                ease: 'power2.quad',
                stagger: 0.1
            })
        }
    }
</script>

<template>
    <div
        v-intersection-observer="onSectionVisible"
        class="flex flex-col items-start gap-10 pt-10 md:flex-row lg:items-center"
    >
        <div class="text-sm">
            {{ t('copyright', { year: new Date().getFullYear() }) }}
        </div>
        <nav class="w-full flex-1 @container">
            <ul class="grid grid-cols-2 flex-wrap gap-4 text-xs underline sm:grid-cols-3 lg:flex">
                <li data-animate-bottom-links>
                    <SpaRouterLink :to="{ name: AcademyRouteName.WEWEB_LEGAL_MENTIONS }">
                        {{ t('legal') }}
                    </SpaRouterLink>
                </li>
                <li data-animate-bottom-links>
                    <SpaRouterLink :to="{ name: AcademyRouteName.WEWEB_CGU }">
                        {{ t('cgu') }}
                    </SpaRouterLink>
                </li>
                <li data-animate-bottom-links>
                    <SpaRouterLink :to="{ name: AcademyRouteName.WEWEB_CGV }">
                        {{ t('cgv') }}
                    </SpaRouterLink>
                </li>
                <li data-animate-bottom-links>
                    <a
                        class="cursor-pointer"
                        href="https://form.typeform.com/to/NDP4e0lz"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {{ t('complaints') }}
                    </a>
                </li>
            </ul>
        </nav>
        <div class="text-sm">
            <a href="mailto:academy@frenchfounders.com">academy@frenchfounders.com</a> -
            <a href="tel:+33652313257">06 52 31 32 57</a>
        </div>
        <nav class="flex items-center justify-end gap-4">
            <a
                data-animate-bottom-links
                href="//www.linkedin.com/company/frenchfounders/"
                target="_blank"
                rel="noopener noreferrer"
            >
                <BaseIcon name="linkedin2" />
            </a>
            <a
                data-animate-bottom-links
                href="//www.instagram.com/frenchfounders_inc/"
                target="_blank"
                rel="noopener noreferrer"
            >
                <BaseIcon name="instagram" />
            </a>
            <a data-animate-bottom-links href="//x.com/frenchfounders" target="_blank" rel="noopener noreferrer">
                <BaseIcon name="x" />
            </a>
            <a
                data-animate-bottom-links
                href="//www.facebook.com/Frenchfounders"
                target="_blank"
                rel="noopener noreferrer"
            >
                <BaseIcon name="facebook" />
            </a>
        </nav>
    </div>
</template>

<i18n lang="json">
{
    "fr": {
        "copyright": "© {year} Frenchfounders. Tous droits réservés",
        "legal": "Mentions légales",
        "cgu": "CGU",
        "cgv": "CGV",
        "cookie_policy": "Politique de cookies",
        "manage_cookies": "Gérer mes cookies",
        "complaints": "Réclamations"
    },
    "en": {
        "copyright": "© {year} Frenchfounders. Tous droits réservés",
        "legal": "Legal informations",
        "cgu": "Terms of use",
        "cgv": "Terms of sale",
        "cookie_policy": "Cookies policy",
        "manage_cookies": "Manage cookies",
        "complaints": "Complaints"
    }
}
</i18n>
