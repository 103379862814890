<script setup lang="ts">
    import { computed } from 'vue'
    import { useI18n } from 'vue-i18n'
    import AcademyFooter from '@/components/layouts/academy/footer/AcademyFooter.vue'
    import PublicClubHeader from '@/components/layouts/public/PublicClubHeader.vue'
    import ContainerTemplate from '@/components/templates/ContainerTemplate.vue'
    import { AcademyRouteName } from '@/vars/RouteName'

    defineProps<{
        asSlot?: boolean
    }>()

    const { t } = useI18n()

    const links = computed(() => {
        return [
            {
                label: t('nav.course'),
                to: { name: AcademyRouteName.INTRODUCE_COURSE }
            }
        ]
    })
</script>

<template>
    <div class="relative !bg-midnight">
        <PublicClubHeader :links="links" />
        <main>
            <slot v-if="asSlot" class="layout__content" />

            <RouterView v-else v-slot="{ Component: ViewComponent }" class="layout__router">
                <Transition name="page-">
                    <Component :is="ViewComponent" class="layout__content" />
                </Transition>
            </RouterView>
        </main>
        <ContainerTemplate public>
            <hr class="my-0 opacity-50" />
            <AcademyFooter class="pb-10 pt-6 text-nuage" />
        </ContainerTemplate>
    </div>
</template>

<i18n lang="json">
{
    "fr": {
        "nav": {
            "course": "Formation"
        }
    }
}
</i18n>
