import 'regenerator-runtime'

import { createRouter, createWebHistory, RouteRecord } from 'vue-router'
import ScrollTrigger from 'gsap/ScrollTrigger'
import i18n from '@/i18n'
import trackingUtm from '@/libs/middlewares/trackingUtm'
import { Lang } from '@/types/Lang.type'
import { checkTokenValidity, getAuthentifiedUser } from './libs/middlewares/auth'
import { RouteContext, RouteMiddleware } from './types/Router.type'
// Non-chunked views
import { SpaName } from './vars/SpaAttr'

export const initRouter = async () => {
    const [appRoutes, clubRoutes, proRoutes, academyRoutes, investRoutes] = await Promise.all([
        import('./routes/app-routes'),
        import('./routes/club-routes'),
        import('./routes/pro-routes'),
        import('./routes/academy-routes'),
        import('./routes/invest-routes')
    ])

    const router = createRouter({
        history: createWebHistory(),
        routes: determineRoutes(),
        scrollBehavior(to, from, savedPosition) {
            return new Promise((resolve) => {
                if (to.hash) {
                    setTimeout(() => {
                        resolve({
                            el: to.hash,
                            behavior: 'smooth'
                        })
                    }, 500)

                    return
                } else if (to.name !== from.name) {
                    resolve({
                        top: 0,
                        behavior: 'smooth'
                    })
                } else if (savedPosition) {
                    return resolve(savedPosition)
                } else {
                    return resolve({})
                }
            })
        }
    })

    function determineRoutes() {
        switch (import.meta.env.VITE_SPA_RUNNING) {
            case SpaName.STORYBOOK:
                return []
            case SpaName.LENETWORK:
                return appRoutes.default as unknown as RouteRecord[]
            case SpaName.PRO:
                return proRoutes.default as unknown as RouteRecord[]
            case SpaName.LECLUB:
                return clubRoutes.default as unknown as RouteRecord[]
            case SpaName.ACADEMY:
                return academyRoutes.default as unknown as RouteRecord[]
            case SpaName.INVEST:
                return investRoutes.default as unknown as RouteRecord[]
            default:
                throw new Error(`Env: ${import.meta.env.VITE_SPA_RUNNING} not supported`)
        }
    }

    // Creates a `nextMiddleware()` function which not only
    // runs the default `next()` callback but also triggers
    // the subsequent Middleware function.
    function nextFactory(context: RouteContext, middleware: RouteMiddleware[], nextIndex: number) {
        const subsequentMiddleware = middleware[nextIndex]
        // If no subsequent Middleware exists,
        // the default `next()` callback is returned.
        if (!subsequentMiddleware) return context.next

        return async () => {
            // Run the default Vue Router `next()` callback first.
            // context.next(...parameters)
            // Then run the subsequent Middleware with a new
            // `nextMiddleware()` callback.
            const nextMiddleware = nextFactory(context, middleware, nextIndex + 1)
            await subsequentMiddleware({ ...context, next: nextMiddleware })
        }
    }

    router.beforeEach(trackingUtm)

    router.beforeEach(() => {
        ScrollTrigger.getAll().forEach((animation) => animation.kill())
    })

    router.afterEach(() => {
        setTimeout(() => {
            ScrollTrigger.refresh()
        }, 1000 /* @debt: Timeout obligatoire pour attendre que le DOM soit bien "mounted", sinon les ScrollTrigger ne fonctionne pas */)
    })

    router.beforeEach(async (to, from, next) => {
        if (window.scrollY > 0) {
            window.scrollTo({ top: 0, behavior: 'smooth' })
        }

        let middleware: RouteMiddleware[] = [checkTokenValidity, getAuthentifiedUser] // default middleware

        if (import.meta.env.VITE_SPA_RUNNING !== SpaName.INVEST) {
            i18n.global.locale.value = Lang.FR
        }

        if (to.meta.middleware) {
            const routeMiddlewares = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware]
            middleware = middleware.concat(routeMiddlewares)
        }

        if (to.meta?.context) {
            const context = typeof to.meta?.context === 'function' ? to.meta?.context(to) : to.meta?.context

            if (context === SpaName.LENETWORK) {
                i18n.global.locale.value = Lang.FR
            }
        }

        if (to.meta?.forceLang) {
            const forceLang = typeof to.meta?.forceLang === 'function' ? to.meta?.forceLang(to) : to.meta?.forceLang

            if (forceLang === Lang.FR || forceLang === Lang.EN) {
                i18n.global.locale.value = forceLang
            }
        }

        // Interval parce que "$crisp" est plus ou moins lent à être chargé en fonction de GTM
        if (to.meta?.hideCrisp) {
            const intervalId = setInterval(() => {
                window.$crisp?.push(['do', 'chat:hide'])
                clearInterval(intervalId)
            }, 100)
        } else {
            const intervalId = setInterval(() => {
                window.$crisp?.push(['do', 'chat:show'])
                clearInterval(intervalId)
            }, 100)
        }

        document.title = (to.meta?.title as string) || 'Frenchfounders'

        const context = {
            from,
            next,
            router,
            to
        }
        const nextMiddleware = nextFactory(context, middleware, 1)

        return await middleware[0]({ ...context, next: nextMiddleware })
    })

    return router
}
