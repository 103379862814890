import { computed, ComputedRef } from 'vue'
import { useI18n } from 'vue-i18n'
import { ProfileNavigationItem } from '@/libs/navigations/useProfileNavigation'
import { useAuthStore } from '@/libs/stores/auth.store'
import { RoleName } from '@/vars/AuthAttr'
import { ClubRouteName, RouteName } from '@/vars/RouteName'

export default function useAccountProfileNavigation(): ComputedRef<ProfileNavigationItem[]> {
    const authStore = useAuthStore()

    const { t } = useI18n({
        messages: {
            fr: {
                nav: {
                    account: 'Mon Compte',
                    profile: 'Mon profil',
                    membership: 'Mes abonnements',
                    login_details: 'Mes identifiants'
                }
            },
            en: {
                nav: {
                    account: 'My account',
                    profile: 'My profile',
                    membership: 'My subscriptions',
                    login_details: 'My infos'
                }
            }
        },
        useScope: 'global'
    })

    const defaultNav = computed<ProfileNavigationItem[]>(() => {
        return [
            {
                key: 'account',
                label: t('nav.account'),
                children: [
                    {
                        label: t('nav.profile'),
                        to: authStore.hasRole(RoleName.LECLUB)
                            ? { name: ClubRouteName.MEMBER_PROFILE, params: { memberIdOrMe: 'me' } }
                            : { name: RouteName.MEMBERS_PROFIL, params: { memberId: 'me' } }
                    },
                    {
                        label: t('nav.membership'),
                        to: { name: RouteName.ACCOUNT_MEMBERSHIP }
                    },
                    {
                        label: t('nav.login_details'),
                        to: { name: RouteName.ACCOUNT_INFO }
                    }
                ]
            }
        ]
    })

    return defaultNav
}
