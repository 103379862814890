<script setup lang="ts">
    import { useI18n } from 'vue-i18n'
    import { useRoute } from 'vue-router'
    import AppSwitcher from '@/components/layouts/network/AppSwitcher.vue'
    import ContainerTemplate from '@/components/templates/ContainerTemplate.vue'
    import BaseButton from '@/components/ui/actions/BaseButton.v2.vue'
    import LogoMedia from '@/components/ui/medias/LogoMedia.vue'
    import SpaRouterLink from '@/components/ui/navigations/SpaRouterLink.vue'
    import useBreakpoints from '@/libs/compositions/useBreakpoints'
    import { useAuthStore } from '@/libs/stores/auth.store'
    import { useSegment } from '@/libs/vendors/segment/segment'
    import { ButtonTheme } from '@/vars/ButtonAttr'
    import { RouteName } from '@/vars/RouteName'
    import HeaderDesktop from './partials/HeaderDesktop.vue'
    import HeaderMobile from './partials/HeaderMobile.vue'

    withDefaults(
        defineProps<{
            theme?: 'light' | 'dark'
        }>(),
        {
            theme: 'light'
        }
    )

    const { analytics } = useSegment()
    const { t } = useI18n()
    const route = useRoute()
    const authStore = useAuthStore()
    const { breakpoints } = useBreakpoints()
</script>

<template>
    <ContainerTemplate
        v-if="!authStore.isLoggedIn || route.name === RouteName.HOME_PUBLIC"
        tag="header"
        public
        class="relative z-10 flex items-center justify-between gap-10 !py-10"
    >
        <SpaRouterLink :to="{ name: RouteName.HOME }">
            <LogoMedia :dark="theme === 'dark'" class="h-full max-h-8" />
        </SpaRouterLink>
        <div class="inline-flex flex-row-reverse items-center gap-6 sm:flex-row">
            <AppSwitcher :theme="theme" />
            <BaseButton
                :theme="theme === 'dark' ? ButtonTheme.TERTIARY : ButtonTheme.GHOST"
                :to="{ name: RouteName.LOGIN }"
                @click="analytics.track('public:home:show')"
            >
                {{ t('login') }}
            </BaseButton>
        </div>
    </ContainerTemplate>
    <Component
        :is="breakpoints.xs || breakpoints.sm || breakpoints.md ? HeaderMobile : HeaderDesktop"
        v-else
        class="header"
    />
</template>

<style lang="scss" scoped>
    .header {
        position: relative;
        z-index: 100;
    }
</style>

<i18n lang="json">
{
    "fr": {
        "login": "Se connecter"
    },
    "en": {
        "login": "Log in"
    }
}
</i18n>
