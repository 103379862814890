<script setup lang="ts">
    import { useI18n } from 'vue-i18n'
    import SpaRouterLink from '@/components/ui/navigations/SpaRouterLink.vue'
    import { ClubRouteName } from '@/vars/RouteName'

    const { t } = useI18n()
</script>

<template>
    <SpaRouterLink :to="{ name: ClubRouteName.MEMBER_RECOMMEND }" class="block px-6r pb-2r hover:opacity-50">
        <h5 class="m-0 p-0 text-base font-bold">
            {{ t('title') }}
        </h5>
        <div class="text-xs">
            {{ t('subtitle') }}
        </div>
    </SpaRouterLink>
</template>

<i18n lang="json">
{
    "fr": {
        "title": "Recommander un contact",
        "subtitle": "Partagez les opportunités d’une communauté d’influence"
    }
}
</i18n>
