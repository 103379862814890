import { computed, ComputedRef } from 'vue'
import { useI18n } from 'vue-i18n'
import useAccountProfileNavigation from '@/libs/navigations/common/useAccountProfileNavigation'
import useAdminProfileNavigation from '@/libs/navigations/common/useAdminProfileNavigation'
import { ProfileNavigationItem } from '@/libs/navigations/useProfileNavigation'
import useGroupsQuery from '@/libs/queries/useGroupsQuery'
import { RouteName } from '@/vars/RouteName'

export default function useNetworkProfileNavigation(): ComputedRef<ProfileNavigationItem[]> {
    const adminProfileNavigation = useAdminProfileNavigation()
    const accountProfileNavigation = useAccountProfileNavigation()

    const { t } = useI18n({
        messages: {
            fr: {
                'nav.circles': 'Mes cercles'
            },
            en: {
                'nav.circles': 'My circles'
            }
        },
        useScope: 'global'
    })

    const { data: myGroupsResults } = useGroupsQuery({ isJoined: true })

    const myCircles = computed(() => myGroupsResults.value?.data || [])

    const networkNav = computed<ProfileNavigationItem[]>(() => {
        const items = [...adminProfileNavigation.value, ...accountProfileNavigation.value]

        if (myCircles.value.length > 0) {
            items.push({
                label: t('nav.circles'),
                children: myCircles.value.map((circle) => {
                    return {
                        label: circle.name,
                        to: { name: RouteName.CIRCLE_SINGLE, params: { slug: circle.slug } }
                    }
                })
            })
        }

        return items
    })

    return networkNav
}
