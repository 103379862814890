/**
 * Le prefix des routes nous sert de repère pour identifier plus facilement les routes en cas d'erreur.
 */
function prefixRoutesWith<T extends Record<string, string>>(prefix: string, routes: T) {
    return Object.entries(routes).reduce((finalRoutes, [routeName, routeValue]) => {
        return {
            ...finalRoutes,
            [routeName]: `${prefix}__${routeValue}`
        } as T
    }, {} as T)
}

export const RouteName = prefixRoutesWith('app', {
    HOME_CEO: 'home_ceo',
    MAGIC_LINK: 'magic-link',
    FUTURE_LEADERS_PASS_GROUP: 'future_leaders_pass_group',
    HOME_ENTREPRENEUR: 'home_entrepreneur',
    HOME_MANAGER: 'home_manager',
    HOME_TALENT: 'home_talent',
    CITY_LANDING: 'city_landing',
    EXPLORE: 'explore',
    APPLY_CLUB_PLAN: 'apply_club_plan',
    APPLY_LECLUB: 'apply_leclub',
    APPLY_LECLUB_LOST: 'apply_leclub_lost',
    APPLY_LECLUB_FOR_BPI: 'apply_leclub_for_bpi',
    APPLY_LECLUB_FOR_OPE_TEN_YEARS: 'apply_leclub_for_ten_years',
    APPLY_LECLUB_FOR_OPE_LOSTS: 'apply_leclub_for_losts',
    APPLY_LECLUB_LOST_FINISHED: 'apply_leclub_lost_finished',
    HOME: 'home',
    HOME_PUBLIC: 'home_public',
    ARTICLE_SINGLE: 'articleSingle',
    FOLDER_SINGLE: 'folderSingle',
    ARTICLE_LIST: 'articleList',
    RESOURCES_LIST: 'resourcesList',
    RESOURCES_LIST_ARTICLES: 'resourcesArticles',
    RESOURCES_LIST_VIDEOS: 'resourcesVideos',
    RESOURCES_LIST_PODCASTS: 'resourcesPodcasts',
    VIDEO_SINGLE: 'videoSingle',
    PODCAST_SINGLE: 'podcastSingle',
    ONBOARDING_BASE: 'onboarding-base',
    ONBOARDING: 'onboarding', // onboarding profile default
    ONBOARDING_GOAL: 'onboardingGoal',
    ONBOARDING_LOCATION: 'onboardingLocation',
    ONBOARDING_LOCATION_HUB: 'onboardingLocationHub',
    ONBOARDING_JOB: 'onboardingJob',
    ONBOARDING_JOB_YES: 'onboardingJobYes',
    ONBOARDING_AVATAR: 'onboardingAvatar',
    ONBOARDING_COMPLETE: 'onboardingComplete',
    MEMBER_ME: 'memberMe',
    MEMBERS_PROFIL: 'membersProfil',
    JOBS: 'jobsListSelection',
    JOB_LIST_SELECTION: 'jobsListSelection',
    JOB_LIST_PENDING: 'jobsListPending',
    JOB_LIST_ACCEPTED: 'jobsListAccepted',
    JOB_LIST_DECLINED: 'jobsListDeclined',
    JOB_LIST_PROCESSED: 'jobsListProcessed',
    JOB_SINGLE: 'jobSingle',
    CALLBACK_STRIPE: 'callback_stripe',
    TALENT_PROFILE: 'talentProfile',
    TALENT_ONBOARDING: 'talentOnboarding',
    NOT_FOUND: 'not-found',
    RETRY_PAYMENT: 'RETRY_PAYMENT',
    ADVANTAGE: 'advantages',
    MEMBERS_LIST: 'membersList',
    SWITCH_TO: 'switch-to',
    EXIT_SWITCH_TO: 'exit-switch-to',
    ACCOUNT_HOME: 'account-home',
    ACCOUNT_MEMBERSHIP: 'account-membership',
    ACCOUNT_SECURITY: 'account-security',
    ACCOUNT_INFO: 'account-info',
    PAYMENT_CONFIRMATION: 'payment-confirmation',
    EVENT_LIST: 'eventList',
    EVENT_LIST_USER: 'eventListUser',
    EVENT_LIST_PHYSICAL: 'eventListPhysical',
    EVENT_LIST_LIVE: 'eventListLive',
    EVENT_LIST_NETWORKING: 'eventListNetworking',
    NEWSLETTER_UNSUBSCRIBE: 'unsubscribe',
    QRCODE_JOIN_RETAIL: 'join_retail',
    GROUP_LIST: 'groups',
    CIRCLE: 'circle',
    CIRCLE_SINGLE: 'circle-single',
    CIRCLE_SINGLE_CHAT: 'circle-single-chat',
    CIRCLE_SINGLE_MEMBERS: 'circle-single-members',
    CIRCLE_SINGLE_EVENTS: 'circle-single-events',
    CIRCLE_SINGLE_WHATSAPP: 'circle-single-whatsapp',
    CIRCLE_SINGLE_NETWORKING_SESSIONS: 'circle-single-networking-sessions',
    CIRCLE_SINGLE_RULES: 'circle-single-rules',
    CIRCLE_SINGLE_ARTICLES: 'circle-single-articles',
    CIRCLE_SINGLE_MODERATION: 'circle-single-moderation',
    CIRCLE_SINGLE_REPLAYS: 'circle-single-replays',
    CIRCLE_SINGLE_FLP_MENTORS: 'circle-single-flp-mentors',
    CIRCLE_JOIN_REGISTER: 'circle-join-register',
    CIRCLE_JOIN_REGISTER_BPI: 'circle-join-register-bpi',
    CIRCLE_JOIN_FROM_INVITE_LINK: 'circle-join-from-invite-link',
    PASSWORD_FORGOT: 'password-forgot',
    RESET_PASSWORD: 'reset-password',
    EVENT_LIST_CLUB: 'event-list-club',
    EVENT_LIST_PUBLIC: 'event-list-public',
    EVENT_LIST_EXTERNAL: 'event-list-external',
    APPLY_CLUB_FINISHED: 'apply-finished',
    LOGIN: 'login',
    LOGOUT: 'logout',
    REGISTER: 'register',
    DISCUSSION_SINGLE: 'discussion-single',
    OFFBOARDING_CLUB: 'offboarding-club',
    NOTIFICATION_PREFERENCES: 'notification-preferences',
    RESOURCES_LISTS_THEMATIC_FOLDERS: 'topics-folders'
})

export const AboutRouteName = prefixRoutesWith('weweb', {
    WEWEB_CHANGEMAKERS: 'changemakers',
    WEWEB_MENTORSHIP: 'mentorship',
    WEWEB_THINK_INTERNATIONAL: 'thinkinternational',
    WEWEB_BUSINESS_SOLUTIONS: 'business-solutions',
    WEWEB_CONTACT_GROUP: 'contact_group',
    WEWEB_CONTACT_BENEFIT: 'contact_benefit',
    WEWEB_CGV: 'page/cgv',
    WEWEB_CLUB_CGV: 'public/cgv',
    WEWEB_CGV_ENTREPRISE: 'page/cgv-entreprise',
    WEWEB_INVESTORS_CLUB: 'investors-club',
    WEWEB_LOOKING_JOB: 'je-cherche-un-job',
    WEWEB_RECRUTE: 'je-recrute',
    WEWEB_AGENCE_RECRUTEMENT: 'agence-recrutement',
    WEWEB_CGV_AGENCE_RECRUTEMENT: 'cgv-agence-recrutement',
    /** @deprecated use ClubRouteName.HOME or ClubRouteName.LANDING_MEMBERSHIP if contains the `#memberships` hash **/
    WEWEB_CLUB: 'leclub',
    WEWEB_CLUB_INFO: 'leclub/info',
    WEWEB_CLUB_MEMBERSHIP: 'le_club_membership',
    WEWEB_LESSUMMITS: 'lessummits',
    WEWEB_LEGAL_MENTIONS: 'mentions-legales',
    WEWEB_NEWS: 'news',
    WEWEB_PRIVACY: 'page/privacy',
    WEWEB_FR_RETAILCLUB: 'fr/retailclub', // => redirection
    WEWEB_EN_RETAILCLUB: 'en/retailclub', // => redirection
    WEWEB_PROGRAMME_SCALE: 'programme-scale',
    WEWEB_EXPATRIATION: 'expatriation',
    WEWEB_INVESTMENT: 'investment',
    WEWEB_CLUB_MEET: 'club/prendre-rendez-vous',
    WEWEB_CLUB_MEET_RM: 'club/prendre-rendez-vous/rm',
    WEWEB_BS_CONTACT: 'bs_contacts',
    WEWEB_ABOUT: 'a-propos',
    WEWEB_ENGAGEMENTS: 'nos-engagements',
    WEWEB_VOTE_NEXT_CITY: 'vote-next-city',
    WEWEB_FR_COOKIES: 'fr/cookies',
    WEWEB_EN_COOKIES: 'en/cookies'
})

export const ProRouteName = prefixRoutesWith('pro', {
    HOME: 'home',
    WELCOME: 'welcome',
    ONBOARDING: 'onboarding',
    ONBOARDING_COMPLETE: 'onboarding-complete',
    ONBOARDING_CHALLENGES: 'onboarding-challenges',
    ONBOARDING_INVITE: 'onboarding-invite',
    JOB_NEW: 'new-job',
    JOB_OFFER_WIZARD: 'job-offer',
    JOB_EDIT: 'edit-job',
    JOBS: 'jobs',
    JOBS_ALL: 'jobs-all',
    JOBS_ACTIVE: 'jobs-active',
    JOBS_ARCHIVED: 'jobs-archived',
    JOBS_DRAFTS: 'jobs-drafts',
    JOB_CANDIDATES: 'job-candidates',
    JOB_CANDIDATE_SINGLE: 'job-candidate-single',
    ADMIN: 'admin',
    ADMIN_MEMBERSHIPS: 'admin-memberships',
    CONTRACTS_MEMBERSHIPS: 'contracts-memberships',
    CONTRACTS_MEMBERSHIPS_AND_INVOICES: 'admin-contracts-memberships-and-invoices',
    CONTRACTS_MEMBERSHIPS_COMPANY_INFORMATIONS: 'admin_contracts_memberships-company-informations',
    COMPANY_APPLY: 'company-apply',
    COMPANY_APPLY_NEW_ACCOUNT: 'company-apply-new-account',
    COMPANY_APPLY_ABOUT_COMPANY: 'company-apply-about',
    COMPANY_APPLY_PAYMENT: 'company-apply-payment',
    BUSINESS_CONNECTIONS: 'business-connections',
    MY_COMPANY_PROFILE: 'my-company-profile',
    EVENTS: 'events',
    TEAM_ALREADY_EXISTING: 'team-already-existing',
    TEAM_JOIN: 'team-join',
    FUNDRAISING: 'fundraising',
    CGV: 'cgv',
    SETTINGS: 'settings',
    NOT_FOUND: 'not-found',
    CANCEL_SUBSCRIPTION: 'cancel-subscription',
    BUSINESS_DIRECTORY_PLANS: 'business_directory_plans',
    CHANGE_SUBSCRIPTION: 'change_subscription'
})

export const ClubRouteName = prefixRoutesWith('club', {
    ACADEMY_HOME: 'academy-home',
    FF_TEAM: 'ff-team',
    APPLY_CLUB: 'apply-club',
    APPLY_CLUB_FINISHED: 'apply-club-finished',
    APPLY_CLUB_BPI: 'apply-club-bpi',
    APPLY_CLUB_WELCOME_BACK: 'apply-club-welcome-back',
    APPLY_CLUB_LOST: 'apply-club-lost',
    APPLY_CLUB_LOST_FINISHED: 'apply-club-lost-finished',
    BOOK_MEETING: 'book-meeting',
    BOOK_MEETING_ACADEMY: 'book-meeting-academy',
    LANDING_MEMBERSHIP: 'landing_membership',
    LANDING_ADDON_WORLDWIDE: 'landing_addon_worldwide',
    LANDING_ADDON_FLP: 'LANDING_ADDON_FLP',
    LANDING_COMMUNITY: 'landing-community',
    LANDING_ADDON_IC: 'landing-addon-ic',
    CITY_LANDING: 'city-landing',
    CREATE_MER: 'create-mer',
    FUTURE_LEADERS_PASS: 'future-leaders-pass',
    NOT_FOUND: 'not-found',
    QRCODE: 'qrcode',
    HOME: 'home',
    ARTICLE_LIST: 'articleList',
    ARTICLE_SINGLE: 'articleSingle',
    VIDEO_LIST: 'video-list',
    VIDEO_SINGLE: 'video-single',
    PODCAST_LIST: 'podcast-list',
    PODCAST_SINGLE: 'podcast-single',
    WORKSPACES: 'workspaces',
    TRIP_PLANNER_ADD: 'trip-planner-add',
    TRIP_PLANNER_LIST: 'trip-planner-list',
    TRIP_PLANNER_SINGLE: 'trip-planner-single',
    HUBS: 'hubs',
    HUB_SINGLE: 'hub-single',
    HUB_SINGLE_CHAT: 'hub-single-chat',
    HUB_SINGLE_MEMBERS: 'hub-single-members',
    HUB_SINGLE_EVENTS: 'hub-single-events',
    EVENTS_LIVE_AND_REPLAY: 'events-live-and-replay',
    EVENT_REPLAY: 'event-replay',
    LISTING_MER: 'listing_mer',
    LISTING_MER_RECEIVED: 'listing_mer_received',
    LISTING_MER_SENT: 'listing_mer_sent',
    LISTING_MER_SCHEDULED: 'listing_mer_scheduled',
    APPLY_VIP: 'apply_vip',
    APPLY_VIP_FINISH: 'apply_vip_finish',
    UNIVERSAL_SEARCH: 'universal_search',
    NEWSLETTER_UNSUBSCRIBE: 'unsubscribe',
    INBOX: 'inbox',
    CLUB: 'club',
    CLUB_SINGLE: 'club-single',
    CLUB_SINGLE_CHAT: 'club-single-chat',
    CLUB_SINGLE_MEMBERS: 'club-single-members',
    CLUB_SINGLE_BOARD_MEMBERS: 'club-single-board-members',
    CLUB_SINGLE_EVENTS: 'club-single-events',
    CLUB_SINGLE_NEWS: 'club-single-news',
    CLUB_SINGLE_RULES: 'club-single-rules',
    CLUB_SINGLE_PROJECT: 'club-single-project',
    CLUB_SINGLE_SOLUTIONS_AND_SERVICES: 'club-single-solutions-and-services',
    CLUB_SINGLE_VIDEOS: 'club-single-videos',
    CLUB_SINGLE_WHATSAPP: 'club-single-whatsapp',
    CLUB_SINGLE_JOIN: 'club-single-join',
    CLUB_SINGLE_JOIN_INVESTORS: 'club-single-join-investors',
    CLUB_SINGLE_ASK_TO_JOIN: 'club-single-ask-to-join',
    CLUB_PROJECT_DETAILS: 'project-single',
    MEMBER_RECOMMEND: 'member-recommend',
    MEMBER_RECOMMEND_HOW_TO: 'member-recommend-how-to',
    MEMBER_RECOMMEND_IMPACT: 'member-recommend-impact',
    MEMBER_RECOMMEND_LIST: 'member-recommend-list',
    ROOM: 'room',
    ONBOARDING_MEMBER: 'onboarding_member',
    ONBOARDING_MEMBER_PASSWORD: 'onboarding_member_password',
    ONBOARDING_MEMBER_ABOUT_YOU: 'onboarding-member-about-you',
    ONBOARDING_MEMBER_COMPANY: 'onboarding_member_company',
    ONBOARDING_MEMBER_INTERESTS: 'onboarding_member_interests',
    ONBOARDING_MEMBER_GOALS: 'onboarding_member_goals',
    ONBOARDING_MEMBER_BUSINESS: 'onboarding_member_business',
    ONBOARDING_MEMBER_PREFERENCES: 'onboarding_member_preferences',
    ONBOARDING_MEMBER_FINISHED: 'onboarding_member_finished',
    EVENTS_LISTING: 'club_events_listing',
    MEMBER_LIST: 'member-list',
    MEMBER_LIST_ALL: 'member-list-all',
    MEMBER_LIST_FAVORITES: 'member-list-favorites',
    MEMBER_LIST_CONTACTS: 'member-list-contacts',
    MEMBER_LIST_SUGGESTIONS: 'member-list-suggestions',
    MEMBER_PREFERENCES: 'member_preferences',
    MEMBER_CHALLENGES_EXPERTIES: 'member_challenges_expertises',
    MEMBER_PROFILE: 'member_profile',
    BENEFITS_SINGLE: 'benefits_single',
    BENEFITS: 'benefits'
})

export const AcademyRouteName = prefixRoutesWith('academy', {
    HOME: 'home',
    INTRODUCE_COURSE: 'introduce_course',
    PRE_REGISTER: 'pre_register',
    WEWEB_CGU: 'weweb-cgu',
    WEWEB_CGV: 'weweb-cgv',
    WEWEB_LEGAL_MENTIONS: 'weweb-mentions-legales'
})

export const InvestRouteName = prefixRoutesWith('invest', {
    HOME: 'home',
    APPLY: 'apply',
    APPLY_FINISHED: 'apply_finished',
    LANDING_INVESTOR_CLUB: 'landing_investor_club',
    JOIN_INVESTOR_CLUB: 'join_ic',
    SINGLE: 'single',
    CHAT: 'chat',
    WHATSAPP: 'whatsapp',
    MEMBERS: 'members',
    PROJECTS: 'projects',
    EVENTS: 'events',
    NEWS: 'news',
    RULES: 'rules',
    VIDEOS: 'videos',
    PROJECT_DETAILS: 'project_details',
    LANDING_INVEST: 'landing_invest',
    LANDING_RAISE_FUNDS: 'landing_raise_funds',
    LANDING_PORTFOLIO: 'landing_portfolio',
    LANDING_TEAM: 'landing_team',
    LANDING_LE_FONDS: 'landing_le_fonds'
})

export const BenefitRouteName = prefixRoutesWith('benefit', {
    BENEFITS: 'benefits',
    BENEFITS_SINGLE: 'benefits_details'
})

export const InboxRouteName = prefixRoutesWith('inbox', {
    INBOX: 'inbox'
})

export const WhatsappRouteName = prefixRoutesWith('whatsapp', {
    WHATSAPP: 'whatsapp'
})

export const LetterManagementRouteName = prefixRoutesWith('letter-management', {
    LETTER: 'letter'
})

export const EventRouteName = prefixRoutesWith('common', {
    EVENT_NETWORKING_SINGLE: 'eventNetworkingSingle',
    EVENT_PAYMENT: 'eventPayment',
    EVENT_DETAILS: 'eventDetails',
    EVENT_PAYMENT_TUNNEL: 'eventPaymentTunnel',
    EVENT_ACTION_CANCELATION: 'eventActionCancelation'
})

export const CompaniesRouteName = prefixRoutesWith('common', {
    COMPANY_HOME: 'company-home',
    COMPANY_SEARCH: 'company-search',
    COMPANY_DETAILS: 'company-details',
    COMPANY_DETAILS_RECOMMENDATIONS: 'company-details-recommendations',
    COMPANY_DETAILS_JOBS: 'company-details-jobs'
})

export const AuthRouteName = prefixRoutesWith('common', {
    OAUTH_LINKEDIN: 'auth-linkedin',
    LOGIN_AND_REDIRECT: 'login-and-redirect',
    LOGIN: 'login',
    REGISTER: 'register'
})

export const ExternalRoute = {
    EVENTS: (search?: string) => `${import.meta.env.VITE_PRIVATE_BASE_URL}/events${search ? '?search=' + search : ''}`,
    EVENT_DETAILS: (id: string | number, slug: string) =>
        `${import.meta.env.VITE_PRIVATE_BASE_URL}/event/${id}/${slug}`,
    EVENT_LIVE: (eventId: number) => `${import.meta.env.VITE_PRIVATE_BASE_URL}/live/${eventId}`,
    EVENT_LIVE_STAGE: (eventId: number, stageId: string) =>
        `${import.meta.env.VITE_PRIVATE_BASE_URL}/live/${eventId}/stage/${stageId}`,
    EVENT_LIVE_NETWORKING: (eventId: number) => `${import.meta.env.VITE_PRIVATE_BASE_URL}/live/${eventId}/networking`,
    PUBLIC_EVENT_DETAILS: (id: string | number, slug: string) =>
        `${import.meta.env.VITE_PRIVATE_BASE_URL}/public/event/${id}/${slug}`,
    LECLUB_HOME: () => `${import.meta.env.VITE_PRIVATE_BASE_URL}`,
    NETWORK_HOME: () => `${import.meta.env.VITE_APP_BASE_URL}`,
    PRO_HOME: () => `${import.meta.env.VITE_PRO_BASE_URL}`,
    CLUB_MEMBER_DETAILS: (memberId: string) => `${import.meta.env.VITE_PRIVATE_BASE_URL}/member/${memberId}`,
    CLUB_MEMBER_ME: () => `${import.meta.env.VITE_PRIVATE_BASE_URL}/member/me`,
    CLUB_MEMBER_LIST: (search?: string) =>
        `${import.meta.env.VITE_PRIVATE_BASE_URL}/members/all${search ? '?search=' + search : ''}`,
    CLUB_MESSAGE: (memberId: string) => `${import.meta.env.VITE_PRIVATE_BASE_URL}/inbox/new/${memberId}`,
    /**
     * @deprecated
     */
    MEMBER_CLUB_PROFILE: (idff: string) => `${import.meta.env.VITE_CLUB_BASE_URL}/member/${idff}`,
    ALL_MEMBER_CLUB: () => `${import.meta.env.VITE_PRIVATE_BASE_URL}/members/all`,
    CLUB_SINGLE_JOIN: (slug: string) => `${import.meta.env.VITE_PRIVATE_BASE_URL}/clubs/${slug}/join`,
    CLUB_TEAM_PAGE: () => `${import.meta.env.VITE_PRIVATE_BASE_URL}/team`,
    CLUB_ARTICLES: () => `${import.meta.env.VITE_PRIVATE_BASE_URL}/articles`,
    CLUB_INBOX_NEW: (idff: string) => `${import.meta.env.VITE_PRIVATE_BASE_URL}/inbox/new/${idff}`,
    REGISTER: (redirectUrl?: string) => {
        let baseRegisterUrl = `${import.meta.env.VITE_PRIVATE_BASE_URL}/register`

        if (redirectUrl) {
            baseRegisterUrl += `?redirectUrl=${redirectUrl}`
        }

        return baseRegisterUrl
    },
    /**
     * @deprecated - Use navigate({ name: RouteName.LOGIN }) instead
     */
    LOGIN: () => `${import.meta.env.VITE_PRIVATE_BASE_URL}/login`,
    /**
     * @deprecated - Use navigate({ name: RouteName.LOGOUT }) instead
     */
    LOGOUT: () => `${import.meta.env.VITE_PRIVATE_BASE_URL}/logout`,
    /**
     * @deprecated Use navigate({ member }) instead
     */
    MEMBER_DETAILS: (idff: string) => `${import.meta.env.VITE_PRIVATE_BASE_URL}/member/${idff}`,
    INBOX_CONVERSATION: (idff: string) => `${import.meta.env.VITE_PRIVATE_BASE_URL}/inbox/new/${idff}`,
    MEMBER_STAKE_AGREEMENT_FORM: (idff: string) => `${import.meta.env.VITE_PRIVATE_BASE_URL}/member/${idff}/agreement`
}

export type SpaRoute = typeof CompaniesRouteName | typeof ClubRouteName | typeof RouteName
