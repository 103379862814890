import { AxiosResponse } from 'axios'
import { RefreshJwt, TeamId } from '@/types/API.type'
import { InviteTeamMemberForm } from '@/types/forms/teams-api/InviteTeamMemberForm.type'
import { StaffTeamBusinnessCenter } from '@/types/forms/teams-api/StaffTeam.type'
import { GetTeamsForm } from '@/types/GetTeamsForm.type'
import { PartnerOnboarding } from '@/types/Partner.type'
import { GetTeamResponse } from '@/types/response/GetTeamResponse.type'
import { ResponseV3, ResponseV3Status } from '@/types/response/ResponseV3.type'
import { GetMyTeams } from '@/types/response/team-api/GetMyTeams.type'
import { GetPendingRequests } from '@/types/response/team-api/GetPendingRequests.type'
import { JoinTeam, Meta } from '@/types/response/team-api/JoinTeam.type'
import { TeamConfiguration } from '@/types/response/team-api/TeamConfiguration.type'
import { TeamContract, TeamContractDetails } from '@/types/response/team-api/TeamContract.type'
import { TeamInvitation } from '@/types/response/team-api/TeamInvitation.type'
import { TeamUserList } from '@/types/response/team-api/TeamUserList.type'
import { Team } from '@/types/Team.type'
import { TeamRole } from '@/types/TeamRole.type'
import Http from './ApiClient'

export default class TeamApi {
    static async getTeamUsers(teamId: string, options?: { format: 'raw' }): Promise<ResponseV3<TeamUserList>> {
        const response = await Http.get(`/v3/teams/${teamId}/users`, {
            params: options
        })

        return response.data
    }

    static async getMyContractList(): Promise<ResponseV3<TeamContract[]>> {
        const response = await Http.get(`/v3/contracts`)

        return response.data
    }

    static async getContract(contractId: string): Promise<ResponseV3<TeamContractDetails>> {
        const response = await Http.get(`/v3/contracts/${contractId}`)

        return response.data
    }

    static async removeTeamUser(teamId: string, teamUserId: string): Promise<ResponseV3<TeamUserList>> {
        const response = await Http.delete(`/v3/teams/${teamId}/users/${teamUserId}`)

        return response.data
    }

    /**
     * Récupère la liste des invitations envoyées.
     */
    static async getInvitedUsers(teamId: string): Promise<ResponseV3<TeamInvitation[]>> {
        const response = await Http.get(`/v3/teams/${teamId}/invitations`)

        return response.data
    }

    /**
     * Rejoint une team de l'espace pro avec une invitation.
     */
    static async joinTeam(
        teamId: string,
        invitationCode: string
    ): Promise<AxiosResponse<Promise<ResponseV3<JoinTeam, Meta>>, unknown>> {
        const response = await Http.get(`/v3/teams/${teamId}/join/${invitationCode}`)

        return response
    }

    /**
     * Supprime une invitation
     */
    static async removeInvitation(teamId: string, invitationId: string): Promise<ResponseV3<void>> {
        const response = await Http.delete(`/v3/teams/${teamId}/invitations/${invitationId}`)

        return response.data
    }

    static async moderateTeamRequest(
        teamId: string,
        requestId: string,
        status: 'accept' | 'deny'
    ): Promise<ResponseV3<unknown>> {
        const response = await Http.post(`/v3/teams/${teamId}/requests/${requestId}/moderate`, {
            status
        })

        return response.data
    }
    static async getPendingRequests(teamId: string): Promise<ResponseV3<GetPendingRequests>> {
        const response = await Http.get(`/v3/teams/${teamId}/requests`)

        return response.data
    }

    static async changeUser(teamId: string, teamUserId: string, user: { role: TeamRole }): Promise<void> {
        const response = await Http.patch(`/v3/teams/${teamId}/users/${teamUserId}`, user)

        return response.data
    }

    static async getMyTeams(): Promise<ResponseV3<GetMyTeams>> {
        const response = await Http.get('/v3/teams')

        return response.data
    }

    static async getTeamConfiguration(): Promise<ResponseV3<TeamConfiguration>> {
        const response = await Http.get('/v3/teams/configuration')

        return response.data
    }

    static async switchToTeam(teamId: string): Promise<ResponseV3Status<string, RefreshJwt>> {
        const response = await Http.get(`/v3/teams/${teamId}/switch-to`)

        return { ...response.data, status: response.status }
    }

    static async createNewTeam(params?: PartnerOnboarding): Promise<ResponseV3Status<TeamId, RefreshJwt>> {
        const response = await Http.post(`/v3/teams`, params)

        return { ...response.data, status: response.status }
    }

    static async getTeam(teamId: string): Promise<ResponseV3<Team>> {
        const response = await Http.get(`/v3/teams/${teamId}`)

        return response.data
    }

    static async getFFTeam(params?: GetTeamsForm): Promise<ResponseV3<GetTeamResponse>> {
        const response = await Http.get(`/v3/ff-team`, { params: params?.zone ? params : undefined })

        return response.data
    }

    static async updatePartner(companyId: number, options: PartnerOnboarding): Promise<void> {
        await Http.patch(`/v3/partners/${companyId}`, options)
    }

    static async inviteTeamMember(teamId: string, form: InviteTeamMemberForm): Promise<void> {
        const response = await Http.post(`/v3/teams/${teamId}/invitations`, form)

        return response.data
    }

    static async resendInvitation(teamId: string, requestInvitationId: string): Promise<void> {
        const response = await Http.post(`/v3/teams/${teamId}/invitations/${requestInvitationId}/resend`)

        return response.data
    }

    static async getStaffFrenchfounders(): Promise<ResponseV3<StaffTeamBusinnessCenter[]>> {
        const response = await Http.get(`/v3/team`)

        return response.data
    }
}
